.company{

  &__intro{
    &--wrap{
      background-color: $c_blur;
    }

    @include f_mid;
    color: #fff;
    text-align: center;

    .heading--intro{
      line-height: 1.8;
      margin: 1.5em auto;
      @include margin0;
      @include max-query($break-md){
        margin-left: -20px;
        margin-right: -20px;
      }
    }
    .para--lead{
      line-height: 2.5;
    }
  }


  &__str{
    &--wrap{
      position: relative;
      .section__bg--left{
        display: block !important;
      }
    }
    &--intro{
    }

    max-width: 920px;
    .heading--sm{
      color: $c_key2;
      margin: 1em auto;
      @include margin0;
      @include max-query($break-md){
        margin: 0.5em auto;
        letter-spacing: 0.1em;
      }
    }
    .heading--intro{
      @include max-query($break-md){
        margin-bottom: 1em;
      }
    }
    .container--inner{
      @include min-query($break-md){
        display: flex;
      }
      .inner--left{
        flex: 1;
        @include min-query($break-md){
          padding-right: 1em;
        }
      }
      .inner--right{
        width: 410px;
        @include max-query($break-lg){
          width: 50%;
        }
        @include max-query($break-md){
          width: auto;
        }
      }
    }

    &.adj1{
      .heading--sm{
        @include max-query($break-md){
          margin-top: 2em;
        }
      }
    }
    
  }



  &__etc{
    &--wrap{

    }
    .navi--etc, .navi--etc--lg{
      color: #fff;
    }
  }


}