.pickinggo{

  &__intro{
    @include min-query($break-md){
      padding-right: 350px;
    }
    .navi--anchor{
      position: relative;
    }
    .intro__catch{
      position: absolute;
      right: 200/1366*100+%;
      // bottom: 20px;
      top: 0;
      width: 476/1366*100+%;
      max-width: 476px;
      font-size: 0;
      line-height: 1;
      img{
        transform: translateY(-50%);
      }
      @include max-query(1280){
        right: 80px;
      }
      @include max-query($break-lg){
        right: 60px;
        width: 47.5%;
      }
    }
  }


  


  &__recommend{
    margin: 45px auto;
    @include margin0;
    @include max-query($break-md){
      margin: 30px auto;
    }
    ul{
      padding-left: 40px;
      li{
        background-color: $c_key2_pale;
        padding: 0.75em 1.5em;
        position: relative;
        @include max-query($break-md){
          padding: 0.5em 1.25em;
        }
        &:before{
          content: "";
          display: block;
          width: 26px;
          height: 26px;
          background-image: url(../img/deco_check.svg);
          background-position: center center;
          position: absolute;
          top: 50%;
          left: -40px;
          margin-top: -13px;
        }
      }
      li+li{
        margin-top: 12px;
      }
    }
  }


  &__fnc{
    .fnc--list{
      display: flex;
      flex-wrap: wrap;
      max-width: 950px;
      margin: 0 auto -80px;
      @include max-query($break-md){
        margin: 0 auto -50px;
      }
      @include max-query($break-sm){
        margin: 0 auto -35px;
      }
      .fnc{
        width: 254/950*100+%;
        margin: 0 94/950*100+% 80px 0;
        @include max-query($break-lg){
        }
        @include max-query($break-md){
          width: 140/310*100+%;
          margin: 0 30/310*100+% 50px 0;
          &:nth-child(2n){
            margin-right: 0;
          }
          &:last-child{
            margin: 0 auto 50px;
          }
        }
        @include max-query($break-sm){
          margin: 0 30/310*100+% 35px 0;
          &:last-child{
            margin: 0 auto 35px;
          }
        }
        @include min-query($break-md){
          &:nth-child(3n){
            margin-right: 0;
          }
        }
      }
      .fnc__icon{
        text-align: center;
        font-size: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        margin: 0 auto 35px;
        background-color: #FFF9F2;
        @include max-query($break-lg){
          width: 150px;
          height: 150px;
        }
        @include max-query($break-md){
          width: 145px;
          height: 145px;
          margin: 0 auto 25px;
        }
        @include max-query($break-sm){
          width: 140px;
          height: 140px;
        }
        >img{
          max-width: 100px;
          @include max-query($break-lg){
            max-width: 95px;
          }
          @include max-query($break-md){
            max-width: 90px;
          }
          @include max-query($break-sm){
            max-width: 85px;
          }
        }
      }
      .fnc__heading{
        @include f_mid;
        font-size: 16px;
        letter-spacing: 0.16em;
        line-height: 1.75;
        text-align: center;
        @include max-query($break-lg){
          font-size: 15px;
        }
        @include max-query($break-sm){
          font-size: 14px;
        }
      }
      .para{
        @include max-query($break-lg){
          font-size: 13px;
        }
        @include max-query($break-sm){
          font-size: 12px;
        }
      }
    }
  }


  &__fig2{
    @include max-query($break-lg){
      max-width: 400px;
      margin: auto;
    }
    @include max-query($break-md){
      padding: 0 5%;
    }
    @include min-query($break-lg){
      max-width: 510px;
      margin: 0 -50px 0 auto;
    }
  }


  





}