.cnv{
  padding: 80px 100px;
  padding-right: 160px;
  background-image: url(../img/bg_cnv.jpg);
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 0;
  &:after{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($c_blk, .4);
    backface-visibility: hidden;
  }
  @include max-query($break-lg){
    padding: 80px 60px;
    padding-right: 100px;
  }
  @include max-query($break-md){
    padding: 80px 50px;
    background-image: url(../img/bg_cnv-sp.jpg);
  }
  @include max-query($break-sm){
    padding: 60px 30px 80px;
  }
  
  &__inner{
    max-width: 835px;
    margin: auto;
    position: relative;
    z-index: 1;
    @include min-query($break-lg){
      display: flex;
      align-items: center;
    }
    .inner__left{
      @include max-query($break-lg){
        margin-bottom: 40px;
      }
      @include min-query($break-lg){
        flex: 1;
        padding-right: 30px;
      }
    }
    .inner__right{
      text-align: center;
      @include min-query($break-lg){
        margin-right: 0;
        margin-left: auto;
        display: flex;
        flex: 1;
      }
      .btn--link{
        flex: 1;
        min-width: 260px;
        max-width: 260px;
        margin-left: auto;
        margin-right: 0;
        @include max-query(1280){
          min-width: 240px;
        }
        @include max-query($break-md){
          display: flex;
          max-width: 300px;
          min-width: auto;
          margin: auto;
        }
        &+.btn--link{
          margin-left: 20px;
          @include max-query($break-md){
            margin: 15px auto 0;
          }
        }
      }
    }
  }


}