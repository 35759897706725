@charset "UTF-8";

*,
*:before,
*:after{
  margin: 0;
  padding: 0;
  list-style: none;
  background-repeat: no-repeat;
  box-sizing:inherit;
  vertical-align:inherit;
}
html, body {
	height: 100%;
  background-color: #000;
}

html{
  font-size: $f_size_base + px;
  // @include max-query($break-md){
  //   font-size: $f_size_base-sp + px;
  // }
}

body{
  background-color: #000;
  text-align: left;
  // letter-spacing: 0.15em;
  color: $c_base;
  @include f_normal;
  line-height: 1.75;
  letter-spacing: 0.06em;
  // font-feature-settings : "palt";
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  box-sizing:border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
a,a img,button{
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
img{
  vertical-align: baseline;
  max-width:100%;
  height: auto;
}

a{
  color: inherit;
}

a:hover,button:hover{
	outline: none;
}
a:disabled,button:disabled,input:disabled{pointer-events:none;}

i, em{
  font-style: normal;
}

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}

input[type="number"]{
  -webkit-appearance: none;
  border-radius: 0;
  -moz-appearance:textfield;
}

input[type="search"] {
  -webkit-appearance: textfield;
  // -webkit-box-sizing: content-box;
}
input[type="search"]:focus {
  outline-offset: -2px;
}
input[type="search"]::-webkit-search-decoration {
  display: none;
}

button, input, textarea{
  font-family: inherit;
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  font-family: inherit;
  font-weight: inherit;
}

::selection {
  background: $c_key3;
  color: $c_base;
}
