.para{

  text-align: justify;
  line-height: 1.9;
  margin: 1.5em auto;
  @include margin0;
  @include min-query($break-md){
    margin: 1.25em auto;
  }

  &--sm{
    font-size: 12px;
    text-align: justify;
    line-height: 1.9;
    margin: 1em auto;
    @include margin0;
  }


  &--lead{
    @include f_mid;
    font-size: 16px;
    line-height: 2.25;
    letter-spacing: 0.16em;
    @include max-query($break-md){
      font-size: 15px;
      line-height: 2.5;
    }
    @include max-query($break-sm){
      font-size: 14px;
    }
    @include max-query($break-min){
      font-size: 13px;
    }
    >p+p{
      margin-top: 2.5em;
    }
  }

  &--more{
    margin: 40px auto;
    @include margin0;
    @include max-query($break-md){
      margin: 30px auto;
    }
    @include max-query($break-sm){
      margin: 25px auto;
    }
  }

}