.toppage{

  &__intro{
    color: #fff;
    // @include min-query($break-lg){
    //   padding: 60px 0;
    // }
    &--wrap{
      background-color: $c_blur;
      @include max-query($break-md){
        padding-top: 0;
      }
      @include min-query($break-md){
        padding-top: 40px;
      }
      @include min-query($break-lg){
        padding-top: 80px;
        padding-bottom: 100px;
      }
    }
    .container--inner{
      @include min-query($break-lg){
        display: flex;
      }
      .inner--left{
        @include min-query($break-lg){
          width: 560/1000*100+%;
        }
      }
      .inner--right{
        flex: 1;
        @include max-query($break-lg){
          margin-top: 60px;
        }
        @include max-query($break-md){
          margin-top: 50px;
        }
        @include max-query($break-sm){
          margin-top: 40px;
        }
        @include min-query($break-lg){
          margin-left: 60px;
        }
      }
    }
    .intro__video{
      display: block;
      font-size: 0;
      line-height: 1;
      max-width: 750px;
      margin: auto;
      position: relative;
      background-color: #000;
      >img{
        display: block;
        transition: opacity 200ms ease;
      }
      @include max-query($break-md){
        max-width: none;
        margin: auto -50px;
      }
      @include max-query($break-sm){
        margin: auto -30px;
      }
      // @include min-query($break-md){
      //   &:hover img{
      //     opacity: 0.7;
      //   }
      // }
      .video__icon{
        width: 120/578*100+%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url(../img/icon_play.svg);
        background-size: contain;
        background-position: center center;
        cursor: pointer;
        &:after{
          content: "";
          display: block;
          padding-top: 100%;
        }
      }
      .video-outer{
        position: relative;
        height: 0;
        padding: 0 0 56.25%;
        overflow: hidden;
      }
      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }
    .intro__lead{
      font-size: 16px;
      line-height: 2.5;
      letter-spacing: 0.16em;
      text-align: center;
      margin: 60px auto;
      @include margin0;
      @include max-query($break-md){
        font-size: 15px;
        letter-spacing: 0.1em;
        line-height: 2.25;
        margin: 50px auto;
      }
      @include max-query($break-sm){
        font-size: 14px;
        margin: 40px -30px;
      }
    }
  }


  &__service{
    position: relative;
    z-index: 1;
    @include min-query(1150){
      padding: 100+120+px 0 60px;
    }
    .heading--lg{
      color: $c_blk;
      @include min-query(1150){
        text-align: right;
      }
    }
    .container--inner{
      @include min-query(1150){
        display: flex;
      }
    }
    .inner--left{
      flex: 1;
      @include max-query(1150){
        max-width: 505px;
        margin: auto;
      }
    }
    .inner--right{
      @include max-query(1150){
        max-width: 75%;
        margin: 80px 0 0 auto;
      }
      @include max-query($break-md){
        max-width: none;
        margin: 60px auto 0;
      }
      @include min-query(1150){
        max-width: 410px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .heading--lg{
          margin-left: -50%;
          margin-right: 0;
          margin-top: -120px;
        }
      }
      .para{
        margin-bottom: 0;
        text-align: left;
      }
      .para--more{
        margin-left: 0;
      }
      .para--sm{
        margin-top: 2em;
        margin-left: 0;
      }
    }
    .carousel--service{
      .swiper-slide{
        .content__img{
          max-width: 200px;
          @include max-query(550){
            max-width: 150px;
          }
        }
      }
    }
    .navi--service{
      @include max-query(1150){
        // max-width: 460px;
        margin-left: auto;
        margin-right: 0;
      }
      @include max-query($break-md){
        max-width: none;
      }
    }
    &--wrap{
      position: relative;
      .section__bg--left{
        background-color: $c_blur;
        position: absolute;
        left: 0;
        bottom: 0;
        width: calc(var(--vw) - (1069px + ((var(--vw) - 1366px) * 0.5)));
        height: calc(100% - 100vh);
        // z-index: -1;
        @include max-query(1258){
          width: 245px;
        }
        @include max-query($break-lg){
          width: 177px;
        }
        @include max-query($break-md){
          width: 125px;
        }
        @include max-query($break-sm){
          width: 81px;
        }
      }
      .section__bg--right{
        background-color: $c_gray_pale;
        z-index: 0;
        @include max-query($break-md){
          width: 100%;
        }
      }
      .title{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        text-align: center;
        .heading--sm{
          color: #fff;
          margin-top: 40vh;
        }
        .heading--title{
          color: $c_key1;
          margin: 0;
          z-index: 1;
          &.heading--title--sticky{
            padding-bottom: 0.25em;
          }
          @include max-query($break-md){
            padding-bottom: 25px;
          }
          @include max-query($break-sm){
            padding-bottom: 0;
          }
        }
      }
    }
    &--title{
      min-height: 100vh;
      // margin: -100px -160px 0 -100px;
      // @include max-query(1150){
      //   margin: -100px -160px -110px -100px;
      // }
      // @include max-query($break-lg){
      //   margin: -100px -100px -110px -60px;
      // }
      // @include max-query($break-md){
      //   margin: -80px -60px -110px -60px;
      // }
      // @include max-query(550){
      //   margin: -80px -60px -170px -60px;
      // }
      // @include max-query($break-sm){
      //   margin: -60px -30px -170px -30px;
      // }
    }
  }


  &__company{
    position: relative;
    .container--inner{
      position: relative;
      padding: 100+90+px 0 0;
      @include max-query($break-lg){
        padding: 75+90+px 0 0;
      }
      @include max-query($break-md){
        padding: 50+70+px 0 0;
      }
      @include max-query($break-sm){
        padding: 30+50+px 0 0;
      }
    }
    .heading--title{
      color: #fff;
      margin: 0;
      &.heading--title--sticky{
        margin-bottom: -90px;
        @include max-query($break-md){
          margin-bottom: -70px;
        }
        @include max-query($break-sm){
          margin-bottom: -50px;
        }
      }
      @include min-query($break-md){
        text-align: right;
      }
    }
    .heading--sm{
      color: #fff;
      @include min-query($break-md){
        position: absolute;
        top: 60px;
        left: 0;
      }
    }
    .heading--lg{
      color: #fff;
      @include min-query($break-md){
        margin-bottom: 40px;
      }
    }
    .para{
      color: #fff;
      @include min-query($break-md){
        max-width: 550px;
        margin-left: 0;
      }
      @include min-query($break-lg){
        max-width: 352px;
      }
    }
    .company__inner{
      @include min-query($break-lg){
        display: flex;
        align-items: center;
      }
      .inner--left{
        @include min-query($break-lg){
          flex: 1;
          padding-right: 20px;
        }
      }
      .inner--right{
        @include max-query($break-md){
          margin-top: 20px;
        }
      }
    }
    &--wrap{
      .section__bg--right{
        background-image: url(../img/toppage_company.jpg);
        background-size: cover;
        background-position: center center;
        @include max-query($break-md){
          width: calc(100% - 80px);
          height: calc(100% - 375px);
        }
        @include max-query($break-sm){
          height: calc(100% - 350px);
        }
        &:after{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(#000, .4);
          backface-visibility: hidden;
        }
      }
    }
  }



  &__recruit{
    position: relative;
    margin-top: 50px;
    @include max-query($break-lg){
      margin-top: 30px;
    }
    @include max-query($break-md){
      margin-top: 0;
    }
    .container--inner{
      position: relative;
      padding: 100+90+px 0;
      @include max-query($break-lg){
        padding: 75+90+px 0;
      }
      @include max-query($break-md){
        padding: 50+70+px 0;
      }
      @include max-query($break-sm){
        padding: 30+50+px 0 40px;
      }
    }
    .heading--title{
      color: #fff;
      margin: 0;
      &.heading--title--sticky{
        margin-bottom: -90px;
        @include max-query($break-md){
          margin-bottom: -70px;
        }
        @include max-query($break-sm){
          margin-bottom: -50px;
        }
      }
    }
    .heading--sm{
      color: #fff;
      @include min-query($break-md){
        position: absolute;
        top: 60px;
        right: 0;
      }
    }
    .heading--lg{
      color: #fff;
      margin-bottom: 40px;
    }
    .recruit__inner{
      position: relative;
      z-index: 0;
      @include min-query($break-md){
        display: flex;
        align-items: center;
      }
      .inner--left{
        @include max-query($break-md){
          padding: 0 10px;
        }
        @include max-query($break-sm){
          padding: 0 5px;
        }
        @include min-query($break-md){
          flex: 1;
          margin-right: -50%;
        }
      }
      .inner--right{
        position: relative;
        z-index: -1;
        @include max-query($break-md){
          margin-top: -20px;
        }
        @include min-query($break-md){
          flex: 1;
          max-width: 600/1000*100+%;
        }
      }
    }
    .recruit__ph{
      position: relative;
      height: 400px;
      @include max-query($break-lg){
        height: 325px;
      }
      @include max-query($break-md){
        height: auto;
      }
      &:before{
        content: "";
        display: block;
        padding-top: 600/900*100+%;
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, .4);
        backface-visibility: hidden;
      }
    }
    &--wrap{
      .section__bg--left{
        background-color: $c_blur;
      }
    }
  }


  &__news{
    position: relative;
    .container--inner{
      position: relative;
      padding: 100+90+px 0 0 80px;
      @include max-query($break-lg){
        padding: 75+90+px 0 0 0;
      }
      @include max-query($break-md){
        padding: 50+70+px 0 40px 0;
      }
      @include max-query($break-sm){
        padding: 30+50+px 0 30px 0;
      }
    }
    .heading--title{
      color: $c_key1;
      margin: 0;
      &.heading--title--sticky{
        margin-bottom: -90px;
        @include max-query($break-md){
          margin-bottom: -70px;
        }
        @include max-query($break-sm){
          margin-bottom: -50px;
        }
      }
      @include min-query($break-md){
        text-align: right;
      }
    }
    .heading--sm{
      @include min-query($break-md){
        position: absolute;
        top: 60px;
        left: 0;
      }
    }
    &--wrap{
      .section__bg--left{
      }
    }
  }




}