.pagefooter{
  background-color: $c_blk;
  color: #fff;
  padding: 60px 100px;
  padding: 60px 100px 60px 50px;
  padding-right: 160px;
  position: relative;
  @include max-query($break-lg){
    padding: 60px 60px;
    padding-right: 100px;
  }
  @include max-query($break-md){
    padding: 60px 50px 40px;
  }
  @include max-query($break-sm){
    padding: 60px 30px 30px;
  }

  &__inner{
    position: relative;
    min-height: 128px;
    max-width: 1100px;
    @include min-query($break-md){
      display: flex;
      align-items: center;
    }
    .inner--left{
      flex: 205;
      min-width: 160px;
      padding-right: 60px;
      @include max-query($break-lg){
        padding-right: 40px;
      }
      @include max-query($break-md){
        padding-right: 0;
      }
    }
    .inner--right{
      flex: 1000-205;
      padding-left: 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @include max-query($break-lg){
        padding-left: 40px;
      }
      @include max-query($break-md){
        padding-left: 0;
      }
      @include min-query($break-md){
        border-left: 1px solid #707070;
      }
      .inner__upper{
        @include max-query($break-md){
          margin-bottom: 60px;
        }
        @include min-query($break-md){
          margin-bottom: 50px;
        }
      }
      .inner__lower{
        width: 100%;
        margin: auto 0 0 0;
        padding-right: 180px;
        @include max-query($break-lg){
          padding-right: 0;
          margin-top: 40px;
        }
      }
    }
  }

  &__logo{
    font-size: 0;
    line-height: 1;
    display: block;
    transition: opacity 200ms ease;
    @include max-query($break-md){
      width: 180px;
      margin-bottom: 50px;
    }
    @include max-query($break-sm){
      width: 120px;
    }
    @include min-query($break-md){
      &:hover{
        opacity: 0.7;
      }
    }
  }

  &__copyright{
    @include f_en;
    font-size: 12px;
    line-height: 1.25;
    margin: 1em 0;
    @include margin0;
  }
  
  &__totop{
    position: absolute;
    right: 20px;
    bottom: -10px;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: url(../img/arrow_totop.svg);
    background-position: center center;
    background-size: 14px;
    background-color: $c_key1;
    transition: background-color 200ms ease;
    @include max-query($break-lg){
      right: 0;
    }
    @include max-query($break-md){
      right: 0;
      bottom: 75px;
    }
    @include min-query($break-md){
      &:hover{
        background-color: $c_key3;
      }
    }
  }

  .navi--sns{
    right: 100px;
    @include max-query($break-md){
      right: auto;
    }
    @include min-query($break-lg){
      position: absolute;
      bottom: 0;
    }
    .svg--fill{
      fill: #fff;
    }
  }
  
  
}