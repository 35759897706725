.service{

  &__intro{
    &--wrap{
      .section__bg--left{
      }
    }

    .container--inner{
      @include min-query($break-lg){
        display: flex;
        align-items: center;
      }
      .inner--left{
        @include min-query($break-lg){
          flex: 1;
          padding-right: 2em;
        }
      }
      .inner--right{
        @include min-query($break-lg){
          width: 495/1000*100+%;
          margin: 0 0 0 auto;
        }
      }
    }

    @include max-query($break-lg){
      text-align: center;
    }
    @include max-query($break-sm){
      text-align: left;
    }

    .heading--intro{
      @include max-query($break-lg){
        margin-bottom: 1.25em;
      }
    }

  }


  &__zero{

    &.container--view{
      max-width: 1053px;
    }
    .zero__intro{
      color: #fff;
      .heading--sm{
        color: $c_key3;
        text-align: center;
      }
      .zero__heading{
        @include f_en_bold;
        font-size: 80px;
        letter-spacing: 0;
        line-height: 1.15;
        text-align: center;
        @include max-query($break-lg){
          font-size: 70px;
        }
        @include max-query($break-md){
          font-size: 60px;
        }
        @include max-query($break-sm){
          font-size: 50px;
        }
      }
      .heading--lg{
        text-align: center;
        margin-top: 0.5em;
      }
      .para{
        max-width: 497px;
        margin: auto;
      }
    }

    .zero__navi{
      &--wrap{
        color: #fff;
        margin: 100px auto;
        @include margin0;
        @include max-query($break-lg){
          margin: 85px auto;
        }
        @include max-query($break-md){
          margin: 75px auto;
        }
        @include max-query($break-sm){
          margin: 60px auto;
        }
        .heading--sec{
          position: relative;
          z-index: 1;
        }
      }

      // margin-top: -85px;
      margin-top: -175px;
      position: relative;
      z-index: 0;
      @include max-query(1280){
        margin-left: -100px;
      }
      @include max-query(1150){
        margin-left: -200px;
        margin-top: -50px;
      }
      @include max-query($break-lg){
        margin: -12.5% 0 0;
      }
      @include max-query($break-md){
        margin: -22.5% 0 0;
      }
      @include min-query($break-lg){
        display: flex;
        align-items: center;
      }

      .navi__bg{
        max-width: 838px;
        margin: 0 -232px 0 -112px;
        position: relative;
        z-index: -1;
        font-size: 0;
        line-height: 1;
        @include max-query($break-lg){
          margin: 0;
          &:after{
            content: "";
            display: block;
            width: 1px;
            // height: 30%;
            height: 45%;
            background-color: #7B7B7B;
            position: absolute;
            top: 50%;
            left: 18.2%;
          }
        }
        @include max-query($break-md){
          margin: 0 -50px;
          overflow: hidden;
          >img{
            max-width: none;
            width: 134%;
            margin-left: calc(-24.3vw + 50px);
          }
          &:after{
            left: 50px;
          }
        }
        @include max-query($break-sm){
          margin: 0 -30px;
          >img{
            margin-left: calc(-24.3vw + 30px);
          }
          &:after{
            left: 30px;
          }
        }
        .heading--sm{
          position: absolute;
          text-align: center;
          color: #fff;
          margin: 0;
          position: absolute;
          top: 22.5%;
          left: 0;
          width: 100%;
          @include max-query($break-md){
            // left: 50%;
            // width: auto;
            // transform: translateX(-14vw);
          }
          @include max-query($break-sm){
            // transform: translateX(-18vw);
          }
        }
      }
      .navi__list{
        position: relative;
        // flex-shrink: 0;
        @include max-query($break-lg){
          padding-left: 18.2%;
          // margin-top: -12.5vw;
          margin-top: 3.5vw;
          position: relative;
        }
        @include max-query($break-md){
          padding-left: 0;
          // margin-top: -22.5vw;
          margin-top: 6.5vw;
        }
        .heading--sm{
          position: absolute;
          top: -40px;
          left: 160px;
          text-align: center;
          margin: 0;
          color: #fff;
          @include max-query($break-lg){
            left: 26%;
          }
          @include max-query($break-md){
            top: -30px;
            left: 50px;
          }
          @include max-query($break-sm){
            top: -25px;
            left: 35px;
          }
        }
        .list__item{
          display: flex;
          align-items: center;
          position: relative;
          padding-left: 135px;
          @include max-query($break-lg){
            padding-left: 50px;
            &:before{
              content: "";
              display: block;
              width: 1px;
              height: calc(20px + 130px);
              background-color: #7B7B7B;
              position: absolute;
              left: 0;
              bottom: 130/2+px;
              z-index: -1;
            }
          }
          @include max-query($break-md){
            &:before{
              height: calc(20px + 96px + 30px);
              bottom: 96/2+px;
            }
          }
          @include max-query($break-sm){
            padding-left: 30px;
          }
          &+.list__item{
            margin-top: 25px;
            @include max-query($break-lg){
              margin-top: 20px;
            }
          }
          &:hover{
            @include min-query($break-md){
              .desc{
                color: $c_key3;
              }
              .line{
                background-color: $c_key3;
                &:before{
                  background-color: $c_key3;
                }
                &:after{
                  border-color: $c_key3;
                }
              }
              .icon{
                &:before{
                  background-color: $c_key3;
                }
                &:after{
                  border-color: $c_key3;
                }
              }
            }
          }
          .line{
            position: absolute;
            top: 50%;
            left: 0;
            width: 135px;
            height: 1px;
            background-color: #7B7B7B;
            transition: background-color 200ms ease;
            @include max-query($break-lg){
              width: 50px;
            }
            @include max-query($break-sm){
              width: 30px;
            }
            &:before{
              content: "";
              display: block;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #fff;
              position: absolute;
              top: 50%;
              left: -4px;
              margin-top: -4px;
              transition: background-color 200ms ease;
            }
            &:after{
              content: "";
              display: block;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border: 1px solid #7B7B7B;
              position: absolute;
              top: 50%;
              left: -8px;
              margin-top: -8px;
              transition: border-color 200ms ease;
            }
          }
          .icon{
            width: 164px;
            flex-shrink: 0;
            font-size: 0;
            line-height: 1;
            padding: 20px;
            position: relative;
            z-index: 0;
            @include max-query($break-lg){
              width: 130px;
              padding: 15px;
            }
            @include max-query($break-md){
              width: 96px;
            }
            &:before, &:after{
              content: "";
              display: block;
              position: absolute;
              border-radius: 50%;
              opacity: 0.6;
              z-index: -1;
            }
            &:before{
              top: 5px;
              left: 5px;
              width: calc(100% - 10px);
              height: calc(100% - 10px);
              background-color: $c_key2;
              transition: background-color 200ms ease;
            }
            &:after{
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: 1px solid $c_key2;
              transition: border-color 200ms ease;
            }
          }
          .desc{
            flex: 1;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.16em;
            padding-left: 2em;
            margin-right: -2em;
            transition: color 200ms ease;
            // white-space: nowrap;
            @include max-query(1280){
              font-size: 15px;
            }
            @include max-query($break-md){
              font-size: 14px;
              margin-right: 0;
              padding-left: 1em;
            }
            @include max-query($break-min){
              font-size: 12px;
            }
            p+p{
              margin-top: 0.5em;
            }
          }
          &.item--2{
            .line{
              @include min-query($break-lg){
                width: 57px;
                left: 78px;
              }
            }
          }
        }
      }
      .platform{
        text-align: center;
        color: $c_key3;
        margin: 0;
        position: absolute;
        bottom: 6.5%;
        left: 0;
        width: 100%;
        .platform--md{
          font-size: 16px;
          line-height: 2.375;
          letter-spacing: 0.6em;
          display: inline-block;
          border: 1px solid $c_key3;
          margin: auto;
          padding: 0.5em 19px 0.5em 32px;
          @include max-query($break-md){
            font-size: 14px;
            // padding-left: 1em;

          }
          @include max-query($break-min){
            font-size: 12px;
          }
        }
      }

    }
    
  }


  &__app{
    .heading--sec+&{
      @include min-query($break-md){
        padding-top: 25px;
      }
    }
    &--wrap{
      position: relative;
      .section__bg--left{
      }
    }

    .container--inner{
      display: flex;
      @include max-query($break-md){
        flex-direction: column-reverse;
      }
      .inner--left{
        width: 494/1000*100+%;
        @include max-query($break-lg){
          width: 42.5%;
        }
        @include max-query($break-md){
          width: auto;
          margin-top: 40px;
        }
      }
      .inner--right{
        padding-left: 100/1000*100+%;
        @include max-query($break-lg){
          padding-left: 50/1000*100+%;
        }
        @include max-query($break-md){
          padding-left: 0;
        }
        @include min-query($break-md){
          flex: 1;
        }
      }
      &.reverse{
        @include min-query($break-md){
          flex-direction: row-reverse;
        }
        .inner--right{
          padding-left: 0;
          padding-right: 100/1000*100+%;
          @include max-query($break-lg){
            padding-right: 50/1000*100+%;
          }
          @include max-query($break-md){
            padding-right: 0;
          }
        }
        .icon{
          margin: 0 auto 0 0;
        }
        .line{
          right: auto;
          left: 164px;
          @include max-query($break-lg){
            left: 130px;
          }
          @include max-query($break-md){
            left: -50px;
          }
          @include max-query($break-sm){
            left: -30px;
          }
          &:before{
            left: calc(100% - 4px);
          }
          &:after{
            left: calc(100% - 8px);
          }
        }
      }
      .heading--lgmd{
        @include min-query($break-lg){
          font-size: 24px;
          letter-spacing: .18em;
        }
      }
    }

    

    .app__feature{
      background-color: #f1f1f1;
      padding: 35px 30px 35px 50px;
      margin: 30px auto;
      @include margin0;
      position: relative;
      @include max-query($break-lg){
        padding: 30px;
      }
      @include max-query($break-md){
        margin: 20px auto;
      }
      &:after{
        content: "";
        display: block;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        position: absolute;
        left: -1px;
        top: -1px;
        background:
          linear-gradient(to right, $c_gray 1px, transparent 1px) 0 0,
          linear-gradient(to right, $c_gray 1px, transparent 1px) 0 100%,
          linear-gradient(to left, $c_gray 1px, transparent 1px) 100% 0,
          linear-gradient(to left, $c_gray 1px, transparent 1px) 100% 100%,
          linear-gradient(to bottom, $c_gray 1px, transparent 1px) 0 0,
          linear-gradient(to bottom, $c_gray 1px, transparent 1px) 100% 0,
          linear-gradient(to top, $c_gray 1px, transparent 1px) 0 100%,
          linear-gradient(to top, $c_gray 1px, transparent 1px) 100% 100%;
        background-repeat: no-repeat;
        background-size: 12px 12px;
        pointer-events: none;
      }
    }

    .app__heading{
      margin: -30px 0 15px;
      @include max-query($break-md){
        margin: 0 auto 25px;
        display: flex;
        align-items: center;
      }
      .heading__inner{
        @include max-query($break-md){
          padding-left: 20px;
        }
        @include min-query($break-md){
          margin-top: 20px;
        }
      }
      .heading--sm{
        letter-spacing: 0.1em;
        margin-bottom: 0.5em;
        color: $c_key2;
        @include max-query($break-md){
          margin-bottom: 0;
        }
      }
      .heading--lg{
        letter-spacing: 0.06em;
        color: $c_blk;
        @include max-query($break-md){
          margin-top: 0;
        }
      }
    }
    .app__icon{
      position: relative;
      .line{
        position: absolute;
        top: 50%;
        right: 164px;
        width: calc(100% + (100 / 406) * 100% - 164px);
        height: 1px;
        background-color: #D8D8D8;
        @include max-query($break-lg){
          right: 130px;
          width: calc(100% + (38 / 406) * 100% - 130px);
        }
        @include max-query($break-md){
          right: auto;
          left: -50px;
          width: 50px;
        }
        @include max-query($break-sm){
          left: -30px;
          width: 30px;
        }
        @include min-query($break-md){
          &:before{
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #D8D8D8;
            position: absolute;
            top: 50%;
            left: -4px;
            margin-top: -4px;
          }
          &:after{
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 1px solid #D8D8D8;
            position: absolute;
            top: 50%;
            left: -8px;
            margin-top: -8px;
          }
        }
      }
      .icon{
        width: 164px;
        height: 164px;
        font-size: 0;
        line-height: 1;
        padding: 20px;
        position: relative;
        z-index: 0;
        @include max-query($break-lg){
          width: 130px;
          height: 130px;
          padding: 15px;
        }
        @include max-query($break-md){
          width: 100px;
          height: 100px;
        }
        @include min-query($break-md){
          margin: 0 0 0 auto;
        }
        &:before, &:after{
          content: "";
          display: block;
          position: absolute;
          border-radius: 50%;
          opacity: 0.6;
          z-index: -1;
        }
        &:before{
          top: 5px;
          left: 5px;
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          background-color: $c_key2;
        }
        &:after{
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid $c_key2;
        }
      }
    }
    
    .heading--lgmd{
      @include min-query($break-md){
        margin-right: -1em;
      }
    }

  }


  &__movie{
    &--wrap{
      @include max-query($break-sm){
        padding: 60px 15px;
      }
      .movie__video{
        display: block;
        font-size: 0;
        line-height: 1;
        position: relative;
        background-color: #fff;
        height: 500px;
        margin: auto -160px auto -100px;
        >img{
          transition: opacity 200ms ease;
        }
        @include max-query($break-lg){
          height: 400px;
          margin: auto -100px auto -60px;
        }
        @include max-query($break-md){
          height: 300px;
          margin: auto -50px;
        }
        @include max-query($break-sm){
          height: 210px;
          margin: auto -15px;
        }
        // @include min-query($break-md){
        //   &:hover img{
        //     opacity: 0.7;
        //   }
        // }
        .video__icon{
          width: 124px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-image: url(../img/icon_play.svg);
          background-size: contain;
          background-position: center center;
          cursor: pointer;
          &:after{
            content: "";
            display: block;
            padding-top: 100%;
          }
        }
      }
    }

    .movie__desc{
      color: #fff;
      background-color: $c_key1;
      padding: 90px 45px;
      border-radius: 20px;
      margin-top: -55px;
      position: relative;
      @include max-query($break-lg){
        margin-top: -40px;
        padding: 70px 30px;
        border-radius: 15px;
      }
      @include max-query($break-md){
        margin-top: -30px;
        padding: 40px 30px;
        border-radius: 10px;
      }
      @include max-query($break-sm){
        margin-top: -15px;
      }
      @include max-query($break-min){
        margin-top: -15px;
        padding: 30px 15px;
      }
      .heading--lgmd{
        text-align: center;
        margin-bottom: 1em;
        @include max-query($break-md){
          letter-spacing: 0.06em;
          margin-left: -1em;
          margin-right: -1em;
        }
      }
      .para{
        max-width: 580px;
      }
    }
    .heading--feature{
      @include f_mid;
      color: #fff;
      font-size: 16px;
      letter-spacing: .16em;
      line-height: 2;
      text-align: center;
      position: relative;
      max-width: 580px;
      margin: 50px auto 0;
      @include max-query($break-md){
        margin: 30px auto 0;
      }
      span{
        background-color: $c_key1;
        padding: 0 1em;
      }
      &:before{
        content: "";
        background-color: #fff;
        height: 1px;
        width: 100%;
        z-index: -1;
        position: absolute;
        left: 0;
        transform: translateY(-50%);
        top: 50%;
      }
    }
    .feature--list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 580px;
      margin: 40px auto 0;
      @include max-query(700){
        margin-top: 20px;
        display: block;
      }
    }
    .feature{
      @include f_mid;
      text-align: center;
      color: $c_key1;
      background-color: #fff;
      border-radius: 50%;
      min-width: 173px;
      min-height: 173px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include max-query($break-lg){
        margin-top: 1em;
      }
      @include max-query(700){
        line-height: 1.7;
        min-width: auto;
        min-height: auto;
        padding: 20px;
        margin-top: 10px;
        border-radius: 10px;
        @include margin0;
      }
    }

  }



  &__introduce{
    &--wrap{
      position: relative;
      .introduce__heading{
        @include f_mid;
        font-size: 30px;
        letter-spacing: 0.24em;
        line-height: 1.7;
        @include max-query($break-lg){
          font-size: 26px;
        }
        @include max-query($break-md){
          padding: 20px;
          font-size: 23px;
          letter-spacing: 0.12em;
          line-height: 1.8;
          text-align: center;
        }
        @include max-query($break-sm){
          font-size: 20px;
        }
      }
      >.container--section{
        padding-top: 60px;
        padding-bottom: 60px;
        @include max-query($break-md){
          padding-top: 30px;
          padding-bottom: 30px;
        }
      }
    }

    .container--inner{
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include max-query($break-md){
        display: block;
      }
    }
    .inner--left{
      flex: 1;
    }
    .inner--right{
      width: 63%;
      @include max-query($break-md){
        width: 100%;
      }
    }
    .more{
      @include f_en_semi;
      color: #7B7B7B;
      text-align: center;
      margin-top: 30px;
      @include max-query($break-md){
        margin: 30px auto;
      }
    }


    .introduce__list{
      max-width: 840px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      @include max-query($break-md){
        max-width: 450px;
      }
      li{
        width: 33.33333%;
        margin: 20px 0;
        @include max-query($break-md){
          width: 50%;
        }
      }
      a{
        font-size: 0;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        max-height: 130px;
        min-height: 100px;
        background-color: #fff;
        @include max-query($break-md){
          min-height: 90px;
        }
        @include max-query($break-sm){
          min-height: 80px;
        }
        img{
          max-width: 62%;
          max-height: 75%;
          transition: opacity 200ms ease;
          @include max-query($break-sm){
            max-width: 75%;
          }
        }
        &:hover{
          @include min-query($break-md){
            img{
              opacity: 0.7;
            }
          }
        }
      }
    }
  }



  &__adv{
    .container--inner{
      display: flex;
      @include max-query($break-lg){
        flex-direction: column-reverse;
      }
      @include min-query($break-lg){
        align-items: center;
      }
      .inner--left{
        // @include max-query($break-lg){
        //   max-width: 600px;
        //   margin: auto;
        // }
        @include min-query($break-lg){
          width: 410/1000*100+%;
          margin-right: 40px;
        }
        .heading--lgmd, .heading--intro{
          @include min-query($break-lg){
            margin: 0 0 1em 0;
            &.adj1{
              margin-right: -2em;
            }
          }
        }
        .heading--intro{
          letter-spacing: .21em;
        }
      }
      .inner--right{
        @include min-query($break-lg){
          flex: 1;
          margin: 15px 0 0 auto;
        }
      }

      &.reverse{
        .adv__ph{
          .copy{
            left: auto;
            right: .25em;
            @include max-query($break-md){
              right: 50px;
            }
            @include max-query($break-sm){
              right: 30px;
            }
          }
        }
        @include min-query($break-lg){
          flex-direction: row-reverse;
          .inner--left{
            margin-right: 0;
          }
          .inner--right{
            margin-right: 40px;
          }
          .adv__ph{
            margin-right: auto;
            margin-left: -153px;
          }
          .adv__fig{
            .fig{
              margin: 0 auto 0 0;
            }
          }
        }
      }
    }

    .adv__unit, .adv__ph, .adv__fig{
      .copy{
        @include f_en_bold;
        font-size: 80px;
        line-height: 1;
        letter-spacing: 0;
        color: $c_key3;
        opacity: 0.2;
        @include max-query(1280){
          font-size: 70px;
        }
        @include max-query($break-lg){
          font-size: 70px;
        }
        @include max-query($break-md){
          font-size: 60px;
        }
        @include max-query($break-sm){
          font-size: 56px;
        }
      }
    }

    .adv__unit{
      max-width: 464px;
      position: relative;
      @include max-query($break-lg){
        max-width: 400px;
        margin: -50px auto 50px;
      }
      @include max-query($break-sm){
        margin: -30px auto 30px;
      }
      @include min-query($break-lg){
        margin: 0 -4% 0 auto;
      }
      .unit{
        font-size: 0;
        line-height: 1;
      }
      .fnc{
        color: #fff;
        text-align: center;
        width: 131/464*100+%;
        border-radius: 50%;
        position: absolute;
        border: 1px solid #D8D8D8;
        &:after{
          content: "";
          display: block;
          padding-top: 100%;
        }
        .inner{
          position: absolute;
          top: 7px;
          left: 7px;
          width: calc(100% - 14px);
          height: calc(100% - 14px);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-color: $c_key1;
          @include max-query($break-md){
            top: 5px;
            left: 5px;
            width: calc(100% - 10px);
            height: calc(100% - 10px);
          }
        }
        .en, .jp{
          display: block;
        }
        .en{
          @include f_en_bold;
          font-size: 16px;
          line-height: 1;
          letter-spacing: 0.1em;
          @include max-query($break-lg){
            font-size: 14px;
          }
          @include max-query($break-md){
            font-size: 13px;
          }
          @include max-query($break-sm){
            font-size: 12px;
          }
          @include max-query($break-min){
            font-size: 11px;
          }
        }
        .jp{
          font-size: 12px;
          @include max-query($break-md){
            font-size: 11px;
          }
          @include max-query($break-sm){
            font-size: 10px;
          }
        }
        &.fnc--1{
          top: 13%;
          right: 5%;
        }
        &.fnc--2{
          top: 27%;
          left: 0%;
        }
        &.fnc--3{
          bottom: 17%;
          right: 5%;
        }
      }
      .copy{
        position: absolute;
        bottom: 5%;
        left: -3%;
      }
    }
    .adv__ph{
      position: relative;
      margin-right: -134px;
      margin-left: auto;
      max-width: 637px;
      @include max-query(1260){
        margin-right: -160px;
      }
      @include max-query($break-lg){
        max-width: 600px;
        margin: 0 auto 80px;
      }
      @include max-query($break-md){
        max-width: none;
        margin: -80px -50px 60px;
      }
      @include max-query($break-sm){
        max-width: none;
        margin: -60px -30px 40px;
      }
      .ph{
        font-size: 0;
        line-height: 1;
      }
      .copy{
        position: absolute;
        top: 100%;
        left: 0.25em;
        margin-top: -0.55em;
        @include max-query($break-md){
          font-size: 50px;
          left: 50px;
        }
        @include max-query($break-sm){
          font-size: 36px;
          left: 30px;
        }
      }
    }
    .adv__fig{
      position: relative;
      .fig{
        font-size: 0;
        line-height: 1;
      }
      @include max-query($break-lg){
        max-width: 500px;
        margin: 0 auto 80px;
      }
      @include max-query($break-md){
        margin: 0 auto 60px;
      }
      @include max-query($break-sm){
        margin: 0 auto 20px;
      }
      .copy{
        position: absolute;
        bottom: -35%;
        left: 0;
        @include max-query($break-lg){
          bottom: 0;
        }
        @include max-query($break-md){
          font-size: 50px;
        }
        @include max-query($break-sm){
          font-size: 36px;
        }
      }
    }
  }



  &__case{
    .case__heading{
      .title{
        &:after{
          content: " 様";
        }
      }
      &:after{
        content: "CUSTOMER CASE STUDIES";
        color: $c_key1;
        @include f_en;
        font-size: 12px;
        line-height: 1.25;
        display: block;
        margin: 0 0 0 auto;
        padding-left: 1.5em;
        flex-shrink: 0;
        @include max-query($break-lg){
          font-size: 11px;
        }
        @include max-query($break-md){
          display: none;
        }
      }
      .heading--wrap{
        display: flex;
        flex-direction: column;
      }
      .title--en{
        content: "CUSTOMER CASE STUDIES";
        color: $c_key1;
        @include f_en;
        font-size: 10px;
        line-height: 1.25;
        letter-spacing: 0.1em;
        display: block;
        margin: 0.75em auto 0 0;
        flex-shrink: 0;
        @include min-query($break-md){
          display: none;
        }
      }
    }
  }


  &__flow{
    &--wrap{
      .para--more{
        text-align: center;
        margin: 85px auto;
        @include margin0;
        @include max-query($break-lg){
          margin: 60px auto;
        }
        @include max-query($break-md){
          margin: 50px auto;
        }
        @include max-query($break-sm){
          margin: 40px auto;
        }
        .btn--link{
          height: 50px;
          @include max-query($break-sm){
            width: 100%;
            justify-content: flex-start;
            i{
              width: 100%;
              text-align: left;
            }
          }
        }
      }
      @include max-query($break-sm){
        padding: 60px 30px 80px;
      }
    }
    .heading--sec{
      @include max-query($break-md){
        margin-bottom: 20px;
      }
    }
  }


  &__applink{
    &--wrap{
      background-color: $c_blur;
    }
  }

  &__map{
    .container--inner{
      display: flex;
      align-items: center;
      @include max-query(550){
        display: block;
      }
    }
    .inner--left{
      flex: 1;
      padding-right: 8%;
    }
    .inner--right{
      width: 49.4%;
      @include max-query(550){
        padding: 0 20px;
        width: 100%; 
      }
    }
    .map__img{
      max-width: 494px;
    }
    .heading--sm{
      color: $c_key3;
    }
    .heading--map{
      @include f_mid;
      font-size: 30px;
      line-height: 1.8;
      letter-spacing: 0.24em;
      color: #FFF;
      font-feature-settings: "palt";
      @include max-query($break-lg){
        font-size: 26px;
      }
      @include max-query($break-md){
        font-size: 23px;
        letter-spacing: 0.12em;
        line-height: 1.8;
      }
      @include max-query($break-sm){
        font-size: 20px;
      }
    }
    .para--lead{
      color: #FFF;
      margin: 1.5em auto;
      @include max-query(550){
        margin: .5em auto;
      }
    }
  }

  &__trial{
    margin-top: 80px;
    padding: 60px 88px;
    outline: 1px solid rgb(255, 255, 255);
    outline-offset: -10px;
    @include max-query($break-md){
      margin-top: 40px;
      padding: 50px 30px;
      // padding: 50px 0;
    }
    .heading--trial{
      text-align: center;
      margin-bottom: 2em;
    }
    .heading--sm{
      color: $c_base;
    }
    .heading--lg{
      color: $c_key1;
      font-size: 30px;
      letter-spacing: 0.24em;
      @include max-query($break-md){
        font-size: 26px;
        line-height: 1.6;
      }
      @include max-query($break-sm){
        font-size: 20px;
      }
    }
    .para{
    }
  }


  
}