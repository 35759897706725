.navi{

  &--header{
    @include f_en_bold;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.04em;
    color: #fff;
    display: flex;
    flex-direction: column;
    @include max-query($break-lg){
      font-size: 12px;
    }
    .navi__item{
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      flex: 1;
      max-height: 120px;
      @include max-query($break-lg){
        max-height: 100px;
      }
    }
    .contact{
      // margin: 0 0 auto 0;
      a{
        background-color: $c_key1;
        @include min-query($break-md){
          &:hover{
            background-color: $c_key3;
          }
        }
      }
    }
    .user{
      margin: auto 0 0 0;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      margin-bottom: -1px;
      // max-height: 140px;
      @media all and (min-height: 951px) {
        margin: 0;
      }
    }
    a{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      line-height: 1;
      transition: background-color 200ms ease;
      @include min-query($break-md){
        &:hover{
          background-color: rgba(#fff, .2);
        }
      }
    }
    .current{
      a{
        // pointer-events: none;
        background-color: rgba(255,255,255,0.2);
      }
    }
  }


  &--menu{
    @include f_en_bold;
    font-size: 16px;
    line-height: 1.25;
    color: #fff;
    .navi__item{
      border-bottom: 1px solid #707070;
      a{
        display: block;
        padding: 20px 0;
      }
    }
  }


  &--menu--sm{
    font-size: 13px;
    line-height: 1.5;
    color: #fff;
    .navi__item+.navi__item{
      margin-top: 1.5em;
    }
  }


  &--page{
    .navi__item{
      display: block;
      padding: 30px 70px 30px 0;
      position: relative;
      @include max-query($break-md){
        padding: 20px 60px 20px 0;
      }
      &:before, &:after{
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      &:before{
        background-color: $c_key2_pale;
      }
      &:after{
        background-color: $c_key3;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 500ms ease;
      }
      @include min-query($break-md){
        &:hover{
          &:after{
            transform: scaleX(1);
          }
          .page{
            &:before{
              opacity: 0;
            }
            &:after{
              opacity: 1;
            }
          }
          .page__title{
            color: $c_key3;
          }
        }
      }
    }
    .page{
      // @include min-query($break-md){
        display: flex;
        align-items: center;
        min-height: 44px;
        padding-right: 35px;
        border-right: 1px solid $c_key2_pale;
        position: relative;
        @include max-query($break-md){
          padding-right: 25px;
        }
        &:before, &:after{
          content: "";
          display: block;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: -70px;
          margin-top: -20px;
          background-size: 14px 14px;
          background-repeat: no-repeat;
          background-position: center center;
          transition: opacity 200ms ease;
          @include max-query($break-md){
            right: -60px;
          }
        }
        &:before{
          border: 1px solid $c_key2;
          background-image: url(../img/arrow_link-key2.svg);
        }
        &:after{
          border: 1px solid $c_key3;
          background-image: url(../img/arrow_link-key3.svg);
          opacity: 0;
        }
      // }
      .page__title{
        transition: color 200ms ease;
      }
    }
  }

  &--service{
    width: 100%;
    .navi__item{
      padding-right: 60px;
      &:before, &:after{
        @include min-query($break-md){
          width: calc(100% - 35%);
          left: auto;
          right: 0;
        }
      }
      &:hover{
        .service__img{
          &:before, &:after{
            background:
              linear-gradient(to right, $c_key3 1px, transparent 1px) 0 0,
              linear-gradient(to right, $c_key3 1px, transparent 1px) 0 100%,
              // linear-gradient(to left, $c_key3 1px, transparent 1px) 100% 0,
              // linear-gradient(to left, $c_key3 1px, transparent 1px) 100% 100%,
              linear-gradient(to bottom, $c_key3 1px, transparent 1px) 0 0,
              // linear-gradient(to bottom, $c_key3 1px, transparent 1px) 100% 0,
              linear-gradient(to top, $c_key3 1px, transparent 1px) 0 100%;
              // linear-gradient(to top, $c_key3 1px, transparent 1px) 100% 100%;
            background-repeat: no-repeat;
            background-size: 50% 50%;
          }
        }
      }
    }
    .page{
      padding-right: 25px;
      &:before, &:after{
        right: -60px;
      }
    }
    .service{
      .service__img{
        display: flex;
        font-size: 0;
        line-height: 1;
        width: 35%;
        max-width: 115px;
        flex-shrink: 0;
        padding: 15px;
        min-height: 80px;
        margin: 0 30px -20px 0;
        border: 1px solid $c_key2_pale;
        position: relative;
        >img{
          margin: auto;
        }
        @include max-query($break-md){
          display: none;
        }
        &:before, &:after{
          content: "";
          display: block;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          position: absolute;
          top: -1px;
          background:
            linear-gradient(to right, $c_gray 1px, transparent 1px) 0 0,
            linear-gradient(to right, $c_gray 1px, transparent 1px) 0 100%,
            // linear-gradient(to left, $c_gray 1px, transparent 1px) 100% 0,
            // linear-gradient(to left, $c_gray 1px, transparent 1px) 100% 100%,
            linear-gradient(to bottom, $c_gray 1px, transparent 1px) 0 0,
            // linear-gradient(to bottom, $c_gray 1px, transparent 1px) 100% 0,
            linear-gradient(to top, $c_gray 1px, transparent 1px) 0 100%;
            // linear-gradient(to top, $c_gray 1px, transparent 1px) 100% 100%;
          background-repeat: no-repeat;
          background-size: 12px 12px;
          transition: background-size 300ms ease;
        }
        &:before{
          left: -1px;
        }
        &:after{
          right: -1px;
          transform: scale(-1,1);
        }
      }
      .en{
        display: block;
        @include f_en_bold;
        font-size: 16px;
        margin-bottom: 0.25em;
        line-height: 1.25;
        letter-spacing: 0.1em;
      }
      .jp{
        font-size: 12px;
        display: block;
        line-height: 1.5;
      }
    }
    .page:after{
    }
    .page:before {
      // content: none;
    }
    .icon-sp,.icon-pc{      
      &:before,&:after{
        border: none;
        background-size: 50px 50px;
      }
    }
    .icon-sp{
      &:before{
        background-image: url(../img/icon_phone-key2.svg);
      }
      &:after{
        background-image: url(../img/icon_phone-key3.svg);
        opacity: 0;
      }
    }
    .icon-pc{
      &:before{
        background-image: url(../img/icon_pc-key2.svg);
      }
      &:after{
        background-image: url(../img/icon_pc-key3.svg);
        opacity: 0;
      }
    }
  }


  &--company{
    .navi__item{
      &:before, &:after{
        width: 1px;
        height: calc(100% - 40px);
        left: auto;
        right: 70px;
        bottom: 20px;
        @include max-query($break-md){
          right: 60px;
        }
      }
      &:after{
        transform: scale(1, 0);
        transform-origin: top;
      }
      @include min-query($break-md){
        &:hover{
          &:after{
            transform: scale(1, 1);
          }
        }
      }
    }
    .company{
      border-right: none;
      &:before{
        border: 1px solid #fff;
        background-image: url(../img/arrow_link-wh.svg);
      }
      .company__title{
        color: #fff;
        width: 100%;
        text-align: right;
      }
      .jp{
        @include f_mid;
      }
      .en{
        @include f_en_bold;
      }
      .jp+.en{
        margin-left: 1em;
        @include max-query($break-sm){
          margin-left: 0.75em;
        }
      }
    }
  }



  &--news{
    .navi__item{
      @include max-query($break-md){
        padding: 25px 0;
      }
    }
    .page{
      @include max-query($break-md){
        display: block;
        border-right: none;
        padding-right: 0;
        &:before, &:after{
          display: none;
        }
      }
    }
    .news{
      .news__date{
        height: 24px;
        display: inline-flex;
        align-items: center;
        margin-right: 20px;
        @include max-query($break-md){
          margin-right: 15px;
        }
      }
      .news__tag{
        font-size: 12px;
        height: 24px;
        min-width: 102px;
        @include min-query($break-md){
          margin-right: 40px;
        }
      }
      .news__title{
        display: block;
        flex: 1;
        @include max-query($break-md){
          margin-top: 0.75em;
        }
      }
    }
  }


  &--category{
    @include f_mid;
    font-size: 14px;
    line-height: 1.25;
    // justify-content: center;
    margin: auto;
    @include min-query($break-md){
      display: flex;
      height: 60px;
    }
    &--wrap{
      position: relative;
      @include min-query($break-md){
        margin-bottom: 80px;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        border-bottom: 1px solid $c_gray_pale3;
      }
    }
    .navi__item{
      @include min-query($break-md){
        height: 100%;
      }
      &+.navi__item{
        @include min-query($break-md){
          margin-left: 20px;
        }
      }
      a{
        display: flex;
        height: 100%;
        align-items: center;
        text-align: center;
        padding: 0 10px;
        position: relative;
        transition: color 200ms ease;
        @include max-query($break-md){
          padding: 15px 0;
        }
        @include min-query($break-md){
          white-space: nowrap;
          &:after{
            content: "";
            display: block;
            width: calc(100% - 20px);
            height: 3px;
            position: absolute;
            left: 10px;
            bottom: -1px;
            background-color: $c_key1;
            opacity: 0;
          }
          &:hover{
            color: $c_key1;
          }
        }
      }
    }
    .current{
      a{
        pointer-events: none;
        color: $c_key1;
        &:after{
          opacity: 1;
        }
      }
    }
  }


  &--anchor{
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 50px 10px 50px;
    box-shadow: 0px 0px 30px 0px rgba(#000, 0.1);
    margin: 50px auto;
    @include margin0;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    @include max-query($break-lg){
      margin: 35px auto;
      font-size: 13px;
      padding: 20px 30px 5px 30px;
      border-radius: 15px;
    }
    .navi__item{
      margin: 0 25px 15px 0;
      &:last-child{
        margin-right: 0;
      }
      a{
        display: block;
        padding-right: 15px;
        position: relative;
        transition: color 200ms ease;
        &:after{
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border-left: 1px solid #707070;
          border-bottom: 1px solid #707070;
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -3px;
          transform: rotate(-45deg);
          transition: border-color 200ms ease;
        }
        @include min-query($break-md){
          &:hover{
            color: $c_key3;
            &:after{
              border-color: $c_key3;
            }
          }
        }
      }
    }
  }



  &--etc{
    margin: 100px auto;
    @include margin0;
    @include max-query($break-md){
      margin: 75px auto;
    }
    @include max-query($break-sm){
      margin: 60px auto;
    }
    @include min-query($break-md){
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -50px;
    }
    .navi__item{
      padding: 0 50px;
      border-left: 1px solid #fff;
      @include max-query($break-md){
        padding: 0 0 0 40px;
        &+.navi__item{
          margin-top: 60px;
        }
      }
      @include min-query($break-md){
        display: flex;
        flex-direction: column;
        border-right: 1px solid #fff;
        width: 50%;
        margin: 0 0 50px 0;
        &:nth-child(2n){
          border-left: none;
        }
      }
    }
    .heading{
      @include f_en_bold;
      font-size: 48px;
      line-height: 1.15;
      letter-spacing: 0;
      margin: 20px 0;
      @include margin0;
      @include max-query($break-lg){
        font-size: 42px;
      }
      @include max-query($break-sm){
        font-size: 40px;
        margin: 15px 0;
      }
      @include max-query($break-min){
        font-size: 34px;
      }
    }
    .desc{
      font-size: 16px;
      letter-spacing: 0.1em;
      margin: 0 0 50px;
      @include margin0;
      @include max-query($break-md){
        margin: 0 0 30px;
      }
      @include max-query($break-sm){
        margin: 0 0 20px;
      }
    }
    .more{
      margin: auto 0 0 0;
    }
  }


  &--etc--lg{
    margin: 100px auto;
    @include margin0;
    @include max-query($break-md){
      margin: 75px auto;
    }
    @include max-query($break-sm){
      margin: 60px auto;
    }
    .navi__item{
      background-color: $c_blur;
      padding: 70px 90px;
      @include max-query(1280){
        padding: 60px;
      }
      @include max-query($break-lg){
        padding: 50px;
        text-align: center;
      }
      @include max-query($break-md){
        padding: 40px 30px;
      }
      @include max-query($break-sm){
        padding: 40px 30px;
      }
      @include min-query($break-lg){
        display: flex;
        align-items: center;
      }
      &+.navi__item{
        margin-top: 50px;
      }
    }
    .heading{
      @include f_en_bold;
      font-size: 56px;
      line-height: 1.15;
      @include max-query($break-lg){
        font-size: 52px;
        margin: 0.4em auto;
        @include margin0;
      }
      @include max-query($break-md){
        font-size: 46px;
      }
      @include max-query($break-sm){
        font-size: 42px;
      }
      @include min-query($break-lg){
        margin-right: 0.75em;
      }
    }
    .desc{
      font-size: 16px;
      letter-spacing: 0.1em;
      @include max-query($break-lg){
        margin: 0.5em auto 1.5em;
        @include margin0;
      }
      @include max-query($break-md){
        font-size: 15px;
      }
      @include max-query($break-sm){
        font-size: 14px;
      }
      @include min-query($break-md){
        padding-right: 2em;
      }
    }
    .more{
      @include min-query($break-lg){
        margin: 0 0 0 auto;
        flex-shrink: 0;
      }
    }
  }


  &--applink{
    @include min-query($break-md){
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -30px;
    }
    .navi__item{
      @include max-query($break-md){
        &+.navi__item{
          margin-top: 30px;
        }
      }
      @include min-query($break-md){
        width: calc((100% - 15px) / 2);
        margin: 0 15px 30px 0;
        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
    .applink__img{
      position: relative;
      z-index: 0;
      padding: 56px 53px 36px;
      .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        &:after{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(#000, .4);
          backface-visibility: hidden;
        }
      }
      .logo{
        width: 164px;
        height: 164px;
        margin: auto;
        border-radius: 50%;
        padding: 20px;
        border: 1px solid #D8D8D8;
        position: relative;
        z-index: 0;
        @include max-query($break-lg){
          width: 144px;
          height: 144px;
        }
        @include max-query($break-md){
          width: 134px;
          height: 134px;
          padding: 15px;
        }
        @include max-query($break-sm){
          width: 120px;
          height: 120px;
        }
        &:before{
          content: "";
          display: block;
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          border-radius: 50%;
          background-color: $c_key2;
          opacity: 0.6;
          position: absolute;
          top: 5px;
          left: 5px;
          z-index: -1;
        }
      }
    }
    .applink__info{
      color: #fff;
      background-color: $c_blk;
      padding: 0 70px 70px;
      position: relative;
      @include max-query($break-lg){
        padding: 0 40px 40px;
      }
      @include max-query($break-md){
        padding: 0 30px 50px;
      }
      dt{
        @include f_en_bold;
        font-size: 42px;
        transform: translateY(-50%);
        margin: 0 -60px -0.25em;
        line-height: 1.25;
        text-align: center;
        @include max-query($break-lg){
          font-size: 38px;
          margin: 0 -40px -0.25em;
        }
        @include max-query($break-md){
          font-size: 34px;
          margin: 0 -30px -0.25em;
        }
        @include max-query($break-sm){
          font-size: 30px;
        }
      }
      .para--more{
        text-align: center;
      }
    }
    .copy{
      color: #FFF;
      text-align: center;
      margin-top: 1em;
    }
  }


  &--sns{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px -15px 0;
    .navi__item{
      margin: 0 30px 15px 0;
      a{
        display: block;
        width: 25px;
        .svg--fill{
          transition: fill 200ms ease;
        }
        @include min-query($break-md){
          &:hover .svg--fill{
            fill: $c_key3;
          }
        }
      }
    }
  }


  &--footer{
    @include f_en_bold;
    font-size: 16px;
    line-height: 1.25;
    @include min-query($break-md){
      display: flex;
      flex-wrap: wrap;
      margin: 0 -40px -15px 0;
    }
    @include max-query($break-md){
      columns: 2;
      column-gap: 4em;
    }
    .navi__item{
      @include min-query($break-md){
        margin: 0 40px 15px 0;
      }
      @include max-query($break-md){
        margin-bottom: 1em;
        &:nth-child(4){margin-bottom: 0;}
      }
      &+.navi__item{
        @include max-query($break-md){
          // margin-top: 1em;
        }
      }
      a{
        display: block;
        transition: color 200ms ease;
        @include min-query($break-md){
          &:hover{
            color: $c_key3;
          }
        }
      }
    }
  }


  &--footer--sm{
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1.5;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px -7px 0;
    .navi__item{
      margin: 0 30px 7px 0;
      a{
        display: block;
        transition: color 200ms ease;
        @include min-query($break-md){
          &:hover{
            color: $c_key3;
          }
        }
      }
    }
  }

}