.companyinfo{

  &__intro{
    .para--lead{
      font-weight: 400;
      @include min-query($break-md){
        text-align: center;
      }
    }
  }


  &__profile{
    .profile__table{
      max-width: 836px;
      margin: auto;
      font-size: 14px;
      line-height: 1.8;
      dl{
        padding: 2em 0;
        border-top: 1px solid $c_gray_pale3;
        display: flex;
        @include max-query($break-md){
          padding: 1.5em 0;
        }
        @include max-query($break-sm){
          padding: 1.25em 0;
        }
        dt{
          width: 175px;
          padding-right: 1em;
          @include max-query($break-lg){
            width: 150px;
          }
          @include max-query($break-md){
            width: 110px;
          }
          @include max-query($break-sm){
            width: 85px;
          }
        }
        dd{
          flex: 1;
        }
      }
    }

    .profile__map{
      margin: 50px auto;
      @include margin0;
      position: relative;
      @include max-query($break-sm){
        margin: 20px auto;
      }
      &:after{
        content: "";
        display: block;
        padding-top: 360px;
        @include max-query($break-md){
          padding-top: 300px;
        }
        @include max-query($break-sm){
          padding-top: 200px;
        }
      }
      iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }



}