.heading{

  &--title{
    @include f_en_bold;
    font-size: 120px;
    line-height: 1.05;
    letter-spacing: 0;
    margin: 0.75em auto;
    @include margin0;
    // font-feature-settings: "palt";
    @include max-query($break-lg){
      font-size: 95px;
    }
    @include max-query($break-md){
      font-size: 75px;
    }
    @include max-query($break-sm){
      font-size: 60px;
    }

    &--sticky{
      position: sticky;
      // top: 40vh;
      top: calc(50vh - 1em);
      opacity: 0.2;
    }
  }

  &--sec{
    text-align: center;
    margin: 100px auto;
    @include margin0;
    font-feature-settings: "palt";
    @include max-query($break-lg){
      margin: 80px auto;
    }
    @include max-query($break-md){
      margin: 60px auto;
    }
    @include max-query($break-sm){
      margin: 40px auto;
    }
    .section__bg--left+&, .section__bg--right+&{
      margin-top: 0;
    }
    .heading--sm{
      color: $c_key3;
    }
    .heading--lg{
      color: $c_blk;
    }
  }

  &--lg{
    @include f_mid;
    font-size: 42px;
    line-height: 1.5;
    letter-spacing: 0.12+0.05+em;
    margin: 1.25em auto;
    @include margin0;
    font-feature-settings: "palt";
    @include max-query($break-lg){
      font-size: 34px;
    }
    @include max-query($break-md){
      font-size: 26px;
      line-height: 1.6;
    }
    @include max-query($break-sm){
      font-size: 20px;
    }
    .heading--sm+&{
      margin-top: 0;
      @include max-query($break-md){
        margin-top: 0.5em;
      }
    }
  }


  &--lgmd{
    @include f_mid;
    font-size: 30px;
    letter-spacing: 0.24em;
    line-height: 1.75;
    font-feature-settings: "palt";
    @include max-query($break-lg){
      font-size: 24px;
    }
    @include max-query($break-md){
      font-size: 20px;
      letter-spacing: 0.12em;
    }
    @include max-query($break-sm){
      font-size: 16px;
    }
  }

  &--md{
    @include f_en_bold;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.1+0.05+em;
    margin: 1em auto;
    @include margin0;
    font-feature-settings: "palt";
  }
  &--md--jp{
    @include f_mid;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.1+0.05+em;
    margin: 1em auto;
    @include margin0;
    font-feature-settings: "palt";
  }

  &--sm{
    @include f_en;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.6+0.05+em;
    margin: 1em auto;
    font-feature-settings: "palt";
    @include margin0;
    @include max-query($break-md){
      font-size: 13px;
    }
    @include max-query($break-sm){
      font-size: 12px;
    }
  }

  &--intro{
    @include f_mid;
    font-size: 30px;
    line-height: 1.8;
    letter-spacing: 0.24em;
    font-feature-settings: "palt";
    @include max-query($break-lg){
      font-size: 26px;
    }
    @include max-query($break-md){
      font-size: 23px;
      letter-spacing: 0.12em;
      line-height: 1.8;
    }
    @include max-query($break-sm){
      font-size: 20px;
    }
  }

  &--deco{
    position: relative;
    padding-left: 25px;
    color: $c_key2;
    line-height: 1.6;
    margin: 1.5em auto;
    @include margin0;
    font-feature-settings: "palt";
    &:before{
      content: "";
      display: block;
      width: 6px;
      height: 21px;
      background-image: url(../img/deco_dot-key2.svg);
      background-size: 6px auto;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &--cnv{
    color: #fff;
    @include max-query($break-lg){
      text-align: center;
    }
    .en{
      @include f_en_bold;
      font-size: 56px;
      line-height: 1.15;
      letter-spacing: 0;
      @include max-query($break-md){
        font-size: 48px;
      }
      @include max-query($break-sm){
        font-size: 42px;
      }
    }
    .jp{
      @include f_mid;
      font-size: 16px;
      line-height: 1.6;
      letter-spacing: 0.16em;
      @include max-query($break-md){
        font-size: 15px;
      }
      @include max-query($break-sm){
        font-size: 14px;
      }
    }
    .en+.jp{
      margin-top: 0.75em;
    }
  }

}