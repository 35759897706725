.list{


  &--bullet{
    li{
      padding-left: 1em;
      text-indent: -1em;
      &:before{
        content: "";
        display: inline-block;
        width: 1em;
        height: 1em;
        margin-right: 1em;
        margin-left: -1em;
        margin-bottom: -0.15em;
        border-radius: 50%;
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%207%207%22%3E%3Ccircle%20cx%3D%223.5%22%20cy%3D%223.5%22%20r%3D%223.5%22%20style%3D%22fill%3A%20%23acacac%22%2F%3E%3C%2Fsvg%3E');
        background-size: 7px;
        background-position: center center;
      }
    }
    li+li{
      margin-top: 0.25em;
    }
  }


  &--ordernum{
    counter-reset: num;
    padding-left: 2em;
    @include max-query($break-md){
      padding-left: 1em;
    }
    li{
      padding-left: 1em;
      &:before{
        counter-increment: num;
        content: counter(num) ".";
        display: inline-block;
        text-align: right;
        width: 2em;
        height: 1em;
        margin-left: -2em;
        padding-right: 0.75em;
        white-space: nowrap;
      }
    }
    &.parren{
      padding-left: 3em;
      @include max-query($break-md){
        padding-left: 2em;
      }
      li{
        padding-left:1em;
        &:before{
          counter-increment: num;
          content: "(" counter(num) ")";
          display: inline-block;
          text-align: right;
          width: 3em;
          height: 1em;
          margin-left: -3em;
          padding-right: 0.75em;
          white-space: nowrap;
        }
      }
    }
    li+li{
      margin-top: 1em;
    }
  }

}