@charset "UTF-8";

/*-- view --*/
$view: 1000;

/*-- breakpoint --*/
$break-lg: 1000;
$break-md: 768;
$break-sm: 450;
$break-min: 350;

/*-- keyColor --*/
$c_base: #575757;
$c_blk: #000000;
$c_gray: #ACACAC;
$c_gray_pale: #FAFAFA;
$c_gray_pale2: #F2F2F2;
$c_gray_pale3: #E6E6E6;
$c_key1: #F08A00;
$c_key2: #848484;
$c_key2_pale: #e3e3e3;
$c_key3: #F7B100;
$c_blur: rgba(#fff, .15);
$c_blur2: rgba(#fff, .2);

/*-- font --*/
@mixin f_normal{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin f_mid{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin f_bold{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin f_en{
  font-family: 'Inter', 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin f_en_mid{
  font-family: 'Inter', 'Noto Sans JP', sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin f_en_semi{
  font-family: 'Inter', 'Noto Sans JP', sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin f_en_bold{
  font-family: 'Inter', 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin f_poppins{
  font-family: 'Poppins', 'Inter', 'Noto Sans JP', sans-serif;
  font-weight: 500;
  font-style: normal;
}


$f_size_base: 14;
// $f_size_base-lg: 14;
// $f_size_base-sp: 14;
$f_size_base-sm: 12;


/*-- mixin --*/
@mixin max-query($size){
  @media all and (max-width: $size + px) {
    @content;
  }
}
@mixin min-query($size){
  @media all and (min-width: $size + 1 + px) {
    @content;
  }
}

@mixin margin0{
  &:first-child{
    margin-top: 0;
  }
  &:last-child{
    margin-bottom: 0;
  }
}

//hack
@mixin for_safari{
  _::-webkit-full-page-media, _:future, :root &{
    @content;
  }
}
@mixin for_firefox{
  @-moz-document url-prefix() {
    @content;
  }
}
@mixin for_ie{
  @media all and (-ms-high-contrast: none) {
    @content;
  }
}



.menu--open,
.movie--open{
  overflow: hidden;
}


/*-- import --*/

/* base */
@import "base";

/* vendor */
// @import "vnd__****.scss";
@import "vnd__swiper.min";

/* layout */
// @import "ly__****";
@import "ly__container";

/* block */
// @import "blk__****";
@import "blk__pageheader";
@import "blk__pagefooter";
@import "blk__fv";
@import "blk__toppage";
@import "blk__company";
@import "blk__companyinfo";
@import "blk__service";
@import "blk__pickinggo";
@import "blk__askan";
@import "blk__zerostock";
@import "blk__recruit";
@import "blk__news";
@import "blk__article";
@import "blk__account";
@import "blk__terms";
@import "blk__cnv";
@import "blk__notfound";

/* element */
// @import "el__****";
@import "el__heading";
@import "el__para";
@import "el__list";
@import "el__navi";
@import "el__case";
@import "el__flow";
@import "el__btn";
@import "el__carousel";
@import "el__menu";
@import "el__svg";

/* mod */
// @import "mod__****";
@import "mod__bg";
@import "mod__chr";
@import "mod__display";
@import "mod__img";

/* for_js */
// @import "js__****";
@import "mod__js";
