.case{

  padding: 45px 70px 60px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 30px 0px rgba(#000, 0.1);
  @include max-query($break-lg){
    padding: 40px 40px 50px;
  }
  @include max-query($break-md){
    padding: 30px 30px 40px;
  }
  @include max-query($break-sm){
    padding: 20px 20px 30px;
  }

  &__heading{
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $c_gray_pale3;
    padding-bottom: 20px;
    margin-bottom: 30px;
    @include f_mid;
    color: $c_base;
    font-size: 24px;
    letter-spacing: 0.1em;
    line-height: 1.25;
    @include max-query($break-lg){
      font-size: 20px;
    }
    @include max-query($break-md){
      font-size: 18px;
      margin-bottom: 20px;
    }
    @include max-query($break-sm){
      font-size: 16px;
    }
    
    .heading__img{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0;
      line-height: 1;
      width: 110px;
      min-height: 70px;
      flex-shrink: 0;
      border: 1px solid $c_gray_pale3;
      margin-right: 30px;
      @include max-query($break-lg){
        width: 95px;
        min-height: 65px;
        margin-right: 20px;
      }
      @include max-query($break-md){
        width: 85px;
        min-height: 55px;
        margin-right: 15px;
      }
      @include max-query($break-sm){
        width: 70px;
        min-height: 45px;
      }
      img{
        max-width: 75%;
        max-height: 75%;
      }
    }
  }

  &__intro{
    margin: 30px auto;
    @include margin0;
  }


  &__desc{
  }

  &__voice{
    @include f_mid;
    font-size: 16px;
    letter-spacing: 0.16em;
    margin-bottom: 1.25em;
    @include max-query($break-sm){
      font-size: 14px;
    }
  }

  &--list{
    .case+.case{
      margin-top: 40px;
      @include max-query($break-md){
        margin-top: 30px;
      }
      @include max-query($break-sm){
        margin-top: 20px;
      }
    }
  }

  &__footer{
    margin: 30px auto 0;
    padding-top: 30px;
    @include max-query($break-md){
      margin: auto;
      padding-top: 0;
    }
  }

}