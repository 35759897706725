.btn{

  &--link{
    @include f_en_semi;
    line-height: 1.25;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 0.25em 20px 0.25em 30px;
    color: #fff;
    position: relative;
    transition: background-color 200ms ease, color 200ms ease;
    background-color: $c_key1;
    @include min-query($break-md){
      &:hover{
        background-color: $c_key3;
      }
    }
    &:before{
      content: "";
      display: block;
      width: 6px;
      height: 21px;
      background-image: url(../img/deco_dot-wh.svg);
      background-size: 6px auto;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
    i{
      display: block;
      flex: 1;
      padding-right: 30px;
      position: relative;
      &:after{
        content: "";
        display: block;
        width: 1em;
        height: 1em;
        background-image: url(../img/arrow_link-wh.svg);
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -0.5em;
      }
    }
    .tel{
      padding-left: 1.75em;
      position: relative;
      &:before{
        content: "";
        display: block;
        width: 1.25em;
        height: 1.25em;
        background-image: url(../img/icon_tel-wh.svg);
        background-position: center center;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -1.25/2+em;
      }
    }
  }



  //サイズバリエーション
  &--md{
    font-size: 16px;
    min-height: 50px;
    &:before{
      height: 26px;
    }
    i{
      flex: 1;
      text-align: left;
    }
  }



  //カラバリエーション（使用するものをON）
  /*
  &--blk{
    background-color: $c_blk;
    border: 1px solid $c_blk;
    @include min-query($break-md){
      &:hover{
        color: $c_blk;
        background-color: #fff;
        &:before{
          background-image: url(../img/deco_dot-blk.svg);
        }
        i:after{
          background-image: url(../img/arrow_link-blk.svg);
        }
      }
    }
  }
  */
  /*
  &--blue{
    background-color: $c_key2;
    border: 1px solid $c_key2;
    @include min-query($break-md){
      &:hover{
        color: $c_key2;
        background-color: #fff;
        &:before{
          background-image: url(../img/deco_dot-key2.svg);
        }
        i:after{
          background-image: url(../img/arrow_link-key2.svg);
        }
      }
    }
  }
  */


}