.account{

  max-width: 834px;
  margin: auto;

  .heading--lgmd{
    margin: 3em auto 0.75em;
    @include margin0;
    @include max-query($break-sm){
      font-size: 20px;
    }
  }

  &--list{
    .account+.account{
      margin-top: 80px;
      @include max-query($break-md){
        margin-top: 70px;
      }
      @include max-query($break-sm){
        margin-top: 60px;
      }
    }
  }

  &__inner{
    .para{
      letter-spacing: 0.03em;
    }
    @include min-query($break-md){
      display: flex;
      align-items: flex-end;
    }
    .inner--left{
      @include min-query($break-md){
        padding-right: 3em;
        flex: 1;
        max-width: 550px;
        margin: 0 auto 0 0;
      }
    }
    .inner--right{
      @include max-query($break-md){
        margin-top: 30px;
      }
      @include min-query($break-md){
        width: 260px;
        margin: 0 0 0 auto;
      }
      .para{
        margin: 1em auto;
        @include margin0;
      }
      .btn--link{
        width: 100%;
        height: 50px;
        @include max-query($break-md){
          max-width: 300px;
        }
        @include max-query($break-sm){
          max-width: none;
        }
      }
    }
  }


}