.news{

  &__date{
    @include f_en;
    line-height: 1;
  }

  &__tag{
    @include f_mid;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $c_key2_pale;
    line-height: 1;
    padding: 0 0.5em;
  }

  &__title{
    text-align: justify;
  }

  &__eyecatch{
    font-size: 0;
    line-height: 1;
  }


  &__index{
    &--wrap{
      position: relative;
      z-index: 1;
      @include min-query($break-md){
        padding-bottom: 120px;
      }
      @include min-query($break-lg){
        padding-bottom: 140px;
      }
    }

    .navi--news{
      max-width: 900px;
      margin: auto;
    }

    .wp-pagenavi{
      margin: 100px auto;
      @include margin0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include f_en;
      font-size: 14px;
      line-height: 1;
      @include max-query($break-md){
        margin: 80px auto;
      }
      @include max-query($break-sm){
        margin: 60px auto;
        font-size: 13px;
      }
      .page, .current, .extend, .previouspostslink, .nextpostslink, .first, .last{
        color: $c_key2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin: 0 5px 10px;
        transition: color 200ms ease, border-color 200ms ease;
        @include max-query($break-sm){
          width: 28px;
          height: 28px;
          margin: 0 3px 10px;
        }
        &:not(.extend){
          border: 1px solid $c_key2;
        }
        &.current, &:hover{
          color: $c_key3;
          border-color: $c_key3;
        }
      }
      .extend{
        position: relative;
        &:after{
          content: "";
          display: block;
          width: 50%;
          height: 1px;
          border-top: 4px dotted $c_gray;
          @include max-query($break-sm){
            border-width: 2px;
          }
        }
      }
      .previouspostslink, .nextpostslink{
        &:after, &:before{
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          background-image: url(../img/arrow_link-key2.svg);
        }
        &:before{
          display: none;
          background-image: url(../img/arrow_link-key3.svg);
        }
        &:hover{
          &:before{
            display: block;
          }
          &:after{
            display: none;
          }
        }
      }
      .previouspostslink{
        &:after, &:before{
          transform: scale(-1,1);
        }
      }
      .first, .last{
        border-width: 0px !important;
      }
    }
  }

  &__article{
    max-width: 835px;
    margin: auto;
    .news__info{
      display: flex;
      align-items: center;
      margin: 40px auto;
      @include margin0;
      @include max-query($break-md){
        margin: 30px auto;
      }
      .news__date{
        margin-right: 1.5em;
      }
      .news__tag{
          justify-content: flex-start;
          background-color: transparent;
          padding: 0;
      }
      .news__tag a{
        font-size: 12px;
        height: 24px;
        min-width: 100px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 3px;
        background-color: #e3e3e3;
        padding: 0 0.5em;
      }
    }
    .news__title{
      @include f_mid;
      font-size: 30px;
      letter-spacing: 0.24em;
      line-height: 1.6;
      text-align: left;
      margin: 40px auto;
      @include margin0;
      @include max-query($break-lg){
        font-size: 27px;
      }
      @include max-query($break-md){
        font-size: 24px;
        letter-spacing: 0.12em;
      }
      @include max-query($break-sm){
        font-size: 20px;
        margin: 30px auto;
      }
    }
    .news__eyecatch{
      margin: 70px auto 60px;
      @include margin0;
      @include max-query($break-lg){
        margin: 60px auto 50px;
      }
      @include max-query($break-md){
        margin: 40px auto;
      }
      @include max-query($break-md){
        margin: 30px auto;
      }
    }
    .news__share{
      margin: 60px auto;
      @include margin0;
      @include max-query($break-md){
        margin: 50px auto;
      }
      @include max-query($break-sm){
        margin: 40px auto 50px;
      }
    }
  }


  &__share{
    display: flex;
    .share__heading{
      @include f_en_semi;
      margin-right: 30px;
    }
    .svg--fill{
      fill: $c_base;
    }

  }
;
  &__pagenation{
    @include f_en_semi;
    color: $c_key3;
    padding: 30px 0;
    border-top: 1px solid $c_gray_pale3;
    margin: 60px auto;
    @include margin0;
    @include max-query($break-md){
      margin: 50px auto;
    }
    display: flex;
    align-items: center;
    a{
      display: flex;
      height: 48px;
      align-items: center;
    }
    .arrow{
      display: block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #7B7B7B;
      transition: border-color 200ms ease;
      &:before, &:after{
        content: "";
        display: block;
        background-position: center center;
        background-size: 14px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 200ms ease;
      }
      &:before{
        background-image: url(../img/arrow_link-key2.svg);
      }
      &:after{
        background-image: url(../img/arrow_link-key3.svg);
        opacity: 0;
      }
    }
    .prev, .next{
      position: relative;
      @include max-query($break-md){
        font-size: 0;
      }
      @include min-query($break-md){
        &:hover{
          .arrow{
            border-color: $c_key3;
            &:before{
              opacity: 0;
            }
            &:after{
              opacity: 1;
            }
          }
        }
      }
    }
    .prev{
      margin: 0 auto 0 0;
      padding-left: 65px;
      @include max-query($break-md){
        padding-left: 48px;
      }
      .arrow{
        position: absolute;
        top: 0;
        left: 0;
        &:before, &:after{
          transform: scale(-1,1);
        }
      }
    }
    .next{
      margin: 0 0 0 auto;
      padding-right: 65px;
      @include max-query($break-md){
        padding-right: 48px;
      }
      .arrow{
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .list{
      margin: 0 auto;
      @include min-query($break-md){
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .hide{
      visibility: hidden;
      pointer-events: none;
    }
  }





}
