%svg{
  display: block;
  position: relative;
  &:after{
    content: "";
    display: block;
    width: 100%;
  }
  svg, img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.svg{

  &--logo--acycle{
    @extend %svg;
    &:after{
      padding-top: 86/178*100+%;
    }
  }

  &--sns{
    @extend %svg;
    &:after{
      padding-top: 25/25*100+%;
    }
  }

}
