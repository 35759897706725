.article{

  /* お知らせ */
  &--news{
    font-size: 14px;
    h1, h2, h3, h4, h5, h6{
      @include f_mid;
      &+p{
        margin-top: 1em;
      }
    }
    h1, h2{
      margin: 4em auto 1.25em;
      @include margin0;
      @include max-query($break-sm){
        margin: 3em auto 1.25em;
      }
    }
    h3, h4, h5, h6{
      margin: 2.5em auto 1em;
      @include margin0;
    }
    h2{
      font-size: 16/14+em;
      letter-spacing: 0.16em;
    }
    h3{
      font-size: 15/14+em;
      letter-spacing: 0.16em;
    }
    h4{
      letter-spacing: 0.16em;
    }
    p, ul, ol{
      margin: 1.5em auto;
      @include margin0;
      @include max-query($break-sm){
        line-height: 1.6;
      }
    }
    p{
      text-align: justify;
    }
    strong, b{
      font-weight: 700;
    }
    em{
      color: $c_key1;
    }
    i{
      text-decoration: underline;
    }
    a{
      color: $c_key1;
      text-decoration: underline;
      transition: opacity 200ms ease;
      @include min-query($break-md){
        &:hover{
          opacity: 0.8;
        }
      }
    }
    ul{
      padding-left: 1em;
      li{
        text-align: left;
        &:before{
          content: "・";
          display: inline-block;
          width: 1em;
          text-align: center;
          margin-left: -1em;
        }
        @include max-query($break-sm){
          &+li{
            margin-top: 0.15em;
          }
        }
      }
    }
    ol{
      padding-left: 2em;
      counter-reset: olnum;
      li{
        text-align: left;
        &:before{
          counter-increment: olnum;
          content: counter(olnum) ".";
          display: inline-block;
          width: 2em;
          text-align: center;
          margin-left: -2em;
        }
        @include max-query($break-sm){
          &+li{
            margin-top: 0.15em;
          }
        }
      }
    }
    table{
      line-height: 1.5;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid $c_gray;
      margin: 3em auto;
      @include margin0;
      font-size: 13px;
      @include max-query($break-md){
        font-size: 12px;
      }
      @include max-query($break-sm){
        font-size: 11px;
      }
      th, td{
        border: 1px solid $c_gray;
        padding: 0.5em 0.75em;
      }
      th{
        @include f_mid;
        background-color: $c_gray_pale2;
      }
    }
    figure{
      text-align: center;
      display: block;
      margin: 5em auto;
      @include margin0;
      @include max-query($break-md){
        margin: 4em auto;
      }
      @include max-query($break-sm){
        margin: 3em auto;
      }
      img{
        margin: auto;
      }
      figcaption{
        display: block;
        font-size: 12px;
        margin-top: 0.75em;
      }
    }
    blockquote{
      font-size: 13/14+em;
      display: block;
      position: relative;
      padding: 1.5em 2em;
      margin: 3em auto;
      @include margin0;
      color: $c_key2;
      background-color: $c_gray_pale2;
    }

    hr{
      margin: 4em auto;
      @include margin0;
      border: none;
      border-top: 1px solid $c_key2_pale;
    }
  }



  /* 導入事例 */
  &--case{
    h1, h2, h3, h4, h5, h6{
      @include f_normal;
      font-size: 14px;
      color: $c_key1;
      background-color: $c_gray_pale;
      padding: 0.5em 1em;
      margin: 2.5em auto 1.25em;
      @include margin0;
    }
    p, ul, ol{
      margin: 1em auto;
      @include margin0;
    }
    strong, b{
      font-weight: 700;
    }
    em{
      color: $c_key1;
    }
    i{
      text-decoration: underline;
    }
    a{
      color: $c_key1;
      text-decoration: underline;
    }
    ul{
      padding-left: 1em;
      li{
        text-align: left;
        &:before{
          content: "・";
          display: inline-block;
          width: 1em;
          text-align: center;
          margin-left: -1em;
        }
      }
    }
    ol{
      padding-left: 2em;
      counter-reset: olnum;
      li{
        text-align: left;
        &:before{
          counter-increment: olnum;
          content: counter(olnum) ".";
          display: inline-block;
          width: 2em;
          text-align: center;
          margin-left: -2em;
        }
      }
    }
    table{
      line-height: 1.5;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid $c_gray;
      margin: 2em auto;
      @include margin0;
      font-size: 13px;
      @include max-query($break-md){
        font-size: 12px;
      }
      @include max-query($break-sm){
        font-size: 11px;
      }
      th, td{
        border: 1px solid $c_gray;
        padding: 0.5em 0.75em;
      }
      th{
        @include f_mid;
        background-color: $c_gray_pale2;
      }
    }
    hr{
      margin: 3em auto;
      @include margin0;
      border: none;
      border-top: 1px solid $c_key2_pale;
    }
    blockquote{
      font-size: 13/14+em;
      display: block;
      position: relative;
      padding: 1.5em 2em;
      margin: 3em auto;
      @include margin0;
      color: $c_key2;
      background-color: $c_gray_pale2;
    }


    .article__footer{
      margin: 30px auto 0;
      padding-top: 30px;
      border-top: 1px solid $c_gray_pale3;
      @include max-query($break-sm){
        padding-top: 20px;
      }
      .btn--link{
        color: #fff;
        min-width: 168px;
        text-decoration: none;
        i{
          text-decoration: none;
        }
        @include max-query($break-md){
          width: 100%;
        }
      }
    }
  }

  
}