.terms{

  max-width: 834px;
  margin: auto;

  .heading--lgmd{
    margin: 3em auto 0.5em;
    @include margin0;
    @include max-query($break-sm){
      font-size: 20px;
    }
  }

  .heading--md--jp{
    margin: 3em auto 1em;
    @include margin0;
  }

  .para{
    margin: 2em auto 1.5em;
    @include margin0;
    @include max-query($break-sm){
      margin: 1.5em auto;
    }
  }

  .heading--lgmd+.para,
  .heading--md--jp+.para{
    margin-top: 1em;
  }


}