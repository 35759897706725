/* media query display */
.display{
	&--none{
		&--lg{
			@include max-query($break-lg){
				display: none !important;
			}
		}
		&--md{
			@include max-query($break-md){
				display: none !important;
			}
		}
		&--sm{
			@include max-query($break-sm){
				display: none !important;
			}
		}
	}
	&--inline{
		&--lg{
			display: none !important;
			@include max-query($break-lg){
				display: inline !important;
			}
		}
		&--md{
			display: none !important;
			@include max-query($break-md){
				display: inline !important;
			}
		}
		&--sm{
			display: none !important;
			@include max-query($break-sm){
				display: inline !important;
			}
		}
	}
	&--block{
		&--lg{
			display: none !important;
			@include max-query($break-lg){
				display: block !important;
			}
		}
		&--md{
			display: none !important;
			@include max-query($break-md){
				display: block !important;
			}
		}
		&--sm{
			display: none !important;
			@include max-query($break-sm){
				display: block !important;
			}
		}
	}
	&--flex{
		&--lg{
			display: none !important;
			@include max-query($break-lg){
				display: flex !important;
			}
		}
		&--md{
			display: none !important;
			@include max-query($break-md){
				display: flex !important;
			}
		}
		&--sm{
			display: none !important;
			@include max-query($break-sm){
				display: flex !important;
			}
		}
	}

	&--hr{
		@media (orientation: portrait){
			display: none !important;
		}
	}
	&--vrt{
		display: none !important;
		@media (orientation: portrait){
			display: inline !important;
		}
	}
}
