.pageheader{
  
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 0 30px;
  pointer-events: none;
  transition: background-color 400ms ease;
  @include max-query($break-lg){
    height: 100px;
  }
  @include max-query($break-md){
    height: 80px;
    background-color: rgba($c_blk, .8);
    @supports(backdrop-filter: blur(6px)){
      background-color: rgba($c_blk, .15);
      backdrop-filter: blur(6px);
    }
  }
  .menu--open &{
    background-color: rgba($c_blk, 0);
  }


  &--wrap{

  }

  &__logo{
    font-size: 0;
    line-height: 1;
    display: block;
    transition: opacity 200ms ease;
    width: 123px;
    pointer-events: auto;
    @include max-query($break-lg){
      width: 100px;
    }
    @include max-query($break-md){
      width: 85px;
    }
    @include max-query($break-sm){
      width: 74px;
    }
    @include min-query($break-md){
      &:hover{
        opacity: 0.7;
      }
    }
  }

  &__navi{
    position: fixed;
    top: 0;
    right: 0;
    width: 80px;
    height: 100%;
    z-index: 2;
    overflow-y: auto;
    border-left: 1px solid #fff;
    background-color: rgba($c_blk, .5);
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @supports(backdrop-filter: blur(20px)){
      backdrop-filter: blur(20px);
      background-color: rgba($c_blk, .2);
    }
    @include max-query($break-lg){
      width: 60px;
    }
    @include max-query($break-md){
      display: none;
    }
    @media (max-height: 650px) {
      display: none;
    }
    .navi--header{
      height: 100%;
      min-height: 650px;
    }
  }

}