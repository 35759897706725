.chr{
	/* lang */
	&--en{
		@include f_en;
	}
	&--enbold{
		@include f_en_bold;
	}

	/* align */
	&--left{
		text-align: left !important;
		&--md{
			@include max-query($break-md){
				text-align: left !important;
			}
		}
		&--sm{
			@include max-query($break-sm){
				text-align: left !important;
			}
		}
	}
	&--center{
		text-align: center !important;
		&--md{
			@include max-query($break-md){
				text-align: center !important;
			}
		}
		&--sm{
			@include max-query($break-sm){
				text-align: center !important;
			}
		}
	}
	&--right{
		text-align: right !important;
		&--md{
			@include max-query($break-md){
				text-align: right !important;
			}
		}
		&--sm{
			@include max-query($break-sm){
				text-align: right !important;
			}
		}
	}

	/* color */
	&--wh{
		color: #fff !important;
	}
	&--blk{
		color: $c_blk !important;
	}

	/* break */
	&--nobreak{
		display: inline-block !important;
	}

}
