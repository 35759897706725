.container{

  &--section{
    padding: 125px 100px;
    padding-right: 160px;
    position: relative;
    z-index: 0;
    backface-visibility: hidden;
    @include max-query($break-lg){
      padding: 100px 60px;
      padding-right: 100px;
    }
    @include max-query($break-md){
      padding: 80px 50px;
    }
    @include max-query($break-sm){
      padding: 60px 30px;
    }
    // &+.container--section{
    //   padding-top: 0;
    // }
    .section__bg--left{
      position: absolute;
      top: 0;
      left: 0;
      width: calc(var(--vw) - (1069px + ((var(--vw) - 1366px) * 0.5)));
      height: 100%;
      z-index: -1;
      @include max-query(1258){
        width: 245px;
      }
      @include max-query($break-lg){
        width: 177px;
      }
      @include max-query($break-md){
        width: 125px;
      }
      @include max-query($break-sm){
        width: 81px;
      }
    }
    .section__bg--right{
      position: absolute;
      top: 0;
      right: 0;
      width: calc(1069px + ((var(--vw) - 1366px) * 0.5));
      height: 100%;
      z-index: -1;
      @include max-query(1258){
        width: calc(var(--vw) - 245px);
      }
      @include max-query($break-lg){
        width: calc(var(--vw) - 177px);
      }
    }
    .section__bg--right_thin{
      position: absolute;
      top: 0;
      right: 0;
      width: calc(var(--vw) - (1069px + ((var(--vw) - 1366px) * 0.5)));
      height: 100%;
      z-index: -1;
      @include max-query(1258){
        width: 245px;
      }
      @include max-query($break-lg){
        width: 177px;
      }
      @include max-query($break-md){
        width: 125px;
      }
      @include max-query($break-sm){
        width: 81px;
      }
    }
  }


  &--view{
    max-width: $view+px;
    margin: auto;
  }


  &--ph--split{
    height: 358px;
    position: relative;
    @include max-query($break-lg){
      height: 300px;
    }
    @include max-query($break-md){
      height: 250px;
    }
    @include max-query($break-sm){
      height: 200px;
    }
    .ph{
      height: 100%;
    }
    .copy{
      @include f_en_bold;
      font-size: 80px;
      line-height: 1;
      letter-spacing: 0;
      color: $c_key1;
      opacity: 0.2;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      margin-top: -0.55em;
      padding: 0 250/1920*100+%;
      z-index: 1;
      @include max-query(1366){
        padding: 0 140px;
      }
      @include max-query(1280){
        font-size: 70px;
        padding: 0 100px;
      }
      @include max-query($break-lg){
        font-size: 56px;
        padding: 0 60px;
      }
      @include max-query($break-md){
        font-size: 46px;
        padding: 0 50px;
      }
      @include max-query($break-sm){
        font-size: 36px;
        padding: 0 30px;
      }
      &.right{
        @include min-query($break-md){
          text-align: right;
          &:after{
            content: "";
            display: inline-block;
            width: 0.5em;
          }
        }
      }
    }
    .ph__bdr{
      position: absolute;
      top: 0;
      left: 0;
      width: calc(var(--vw) - (1069px + ((var(--vw) - 1366px) * 0.5)));
      height: 100%;
      background-color: $c_blur;
      border-right: 1px solid #fff;
      @include max-query(1258){
        width: 245px;
      }
      @include max-query($break-lg){
        width: 177px;
      }
      @include max-query($break-md){
        width: 125px;
      }
      @include max-query($break-sm){
        width: 81px;
      }
      &.right{
        left: auto;
        right: 0;
        border-right: none;
        border-left: 1px solid #fff;
      }
    }
  }
  


}
