.carousel{


  &--service{
    max-width: 339px;
    margin: 0;
    position: relative;
    z-index: 0;
    filter: drop-shadow(0px 0px 30px rgba(0,0,0,0.1));
    background-color: #fff;
    border-radius: 20px;
    @include max-query(550){
      margin: auto;
    }
    *{
       box-sizing: border-box;
    }
    .carousel__frame{
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(../img/frame_phone-top.svg), url(../img/frame_phone-bottom.svg);
      background-position: top, bottom;
      background-size: 100% auto, 100% auto;
      &:before{
        content: "";
        display: block;
        width: 100%;
        height: calc(100% - 60px - 240px);
        position: absolute;
        top: 240px;
        left: 0;
        background-image: url(../img/frame_phone-middle.svg);
        background-size: 100% auto;
        background-repeat: repeat-y;
        @include max-query(550){
          height: 85%;
          top: 7.5%;
        }
      }
    }
    .swiper-slide{
      // width: 100% !important;
      padding: 80px 25px 40px;
      height: 560px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      @include max-query(550){
        padding: 12% 20px;
      }
      @include max-query($break-sm){
        height: 540px;
      }
    }
    .bg{
      // position: absolute;
      // top: 0;
      // left: 0;
      img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
        object-position: center;
      }
    }
    .carousel__content{
      text-align: center;
      dt{
        @include f_en_bold;
        font-size: 26px;
        line-height: 1.25;
        letter-spacing: 0.06em;
        color: #FFF;
        margin: .75em auto .25em;
        @include margin0;
      }
      .content__img{
        display: none;
        margin: 50px auto;
        @include margin0;
        @include max-query(550){
          margin: 40px auto;
        }
        @include max-query($break-sm){
          margin: 30px auto;
        }
      }
      .para{
        text-align: inherit;
        color: #FFF;
        @include max-query($break-sm){
          font-size: 13px;
        }
      }
      .para--more{
        margin-top: 30px;
      }
    }
    &--wrap{
      display: inline-block;
      padding: 80px 80px 80px;
      position: relative;
      @include max-query(1150){
        padding: 0 80px 80px;
      }
      @include max-query(550){
        display: block;
        margin: -60px -50px 0;
        overflow: hidden;
        padding: 60px 60px 0;
      }
      @include max-query($break-sm){
        // margin: auto -30px;
        margin: -60px -30px auto;
        padding: 60px 50px 0;
      }
      .swiper-button-prev, .swiper-button-next{
        position: absolute;
        width: 40px;
        height: 40px;
        border: 1px solid $c_gray;
        border-radius: 50%;
        background-image: none;
        transition: border-color 200ms ease;
        position: absolute;
        top: 50%;
        left: auto;
        right: auto;
        margin-top: -20-30+px;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
        &:before, &:after{
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-size: 12px;
          background-position: center center;
          transition: opacity 200ms ease;
        }
        &:before{
          background-image: url(../img/arrow_link-gray.svg);
        }
        &:after{
          background-image: url(../img/arrow_link-key1.svg);
          opacity: 0;
        }
        @include max-query(550){
          margin-top: -20-40+px;
        }
        @include min-query($break-md){
          &:hover{
            border-color: $c_key3;
            &:before{
              opacity: 0;
            }
            &:after{
              opacity: 1;
            }
          }
        }
      }
      .swiper-button-prev{
        left: 0;
        @include max-query(550){
          left: -10px;
        }
        &:before, &:after{
          transform: scale(-1,1);
        }
      }
      .swiper-button-next{
        right: 0;
        @include max-query(550){
          right: -10px;
        }
      }
      .swiper-pagination{
        position: relative;
        display: flex;
        width: auto;
        padding: 0 100px;
        margin: 50px -80px;
        @include margin0;
        -webkit-tap-highlight-color:rgba(0,0,0,0);
        @include max-query(550){
          margin: 50px -50px;
          padding: 0 50px;
        }
        @include max-query($break-sm){
          margin: 50px -30px;
          padding: 0 20px;
        }
        >span{
          flex: 1;
          display: block;
          width: auto;
          height: auto;
          background: none;
          position: relative;
          opacity: 1;
          @include f_en_mid;
          font-size: 12px;
          line-height: 1;
          letter-spacing: 0.1em;
          color: $c_gray;
          text-align: left;
          padding-top: 15px;
          transition: color 200ms ease;
          &:before, &:after{
            content: "";
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
          }
          &:before{
            height: 1px;
            background-color: $c_gray;
            top: 0;
          }
          &:after{
            height: 2px;
            background-color: $c_key3;
            top: 1px;
            transform: translateY(-80%) scaleX(0);
            transform-origin: left;
            transition: none;
          }
          &.swiper-pagination-bullet-active{
            color: $c_key3;
            &:after{
              transition: transform 4s linear;
              transform: translateY(-50%) scaleX(1);
            }
            &.inviewout{
              &:after{
                transform: translateY(-80%) scaleX(0);
              }
            }
          }
          &+span{
            margin-left: 8px;
          }
        }
      }
    }
  }


}