.menu{
  pointer-events: none;
  .menu--open &{pointer-events: auto;}
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  display: flex;
  padding: 80px 50px 0;
  overflow-y: auto;
  display: none;
  opacity: 0;
  backface-visibility: hidden;
  transform: translate3d(0,0,0);
  @supports(backdrop-filter: blur(6px)){
    background-color: rgba($c_blk, .3);
    backdrop-filter: blur(6px);
  }
  @include max-query($break-sm){
    padding: 60px 30px 0;
  }
  @include min-query($break-md){
    display: none !important;
  }
  @media (max-height: 650px) {
    display: block !important;
  }

  &__bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba($c_blk, .8);
    @supports(backdrop-filter: blur(6px)){
      background-color: rgba($c_blk, 0);
    }
  }

  &__inner{
    flex: 1;
    max-width: 400px;
    margin: auto;
    &:after{
      content: "";
      display: block;
      height: 60px;
      width: 100%;
      @include max-query($break-sm){
        height: 40px;
      }
    }
  }

  .navi--sns{
    margin-top: 50px;
    .svg--fill{
      fill: #fff;
    }
  }
  .navi--menu--sm{
    margin-top: 50px;
  }


  &--btn{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    width: 80px;
    height: 80px;
    cursor: pointer;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    @include min-query($break-md){
      display: none !important;
    }
    @media (max-height: 650px) {
      display: block !important;
    }
    span{
      display: block;
      background-color: #fff;
      width: 30px;
      height: 1px;
      position: absolute;
      left: 50%;
      margin-left: -15px;
      &:nth-child(1), &:nth-child(3){
        transition:
          background-color 200ms ease,
          transform 200ms ease,
          top 200ms ease 200ms,
          bottom 200ms ease 200ms;
      }
      &:nth-child(2){
        transition: opacity 200ms ease 200ms;
      }
      &:nth-child(1){
        top: 33px;
      }
      &:nth-child(2){
        top: 39px;
      }
      &:nth-child(3){
        bottom: 34px;
      }
      .menu--open &{
        &:nth-child(1), &:nth-child(3){
          transition:
            background-color 200ms ease,
            transform 200ms ease 200ms,
            top 200ms ease,
            bottom 200ms ease;
        }
        &:nth-child(2){
          transition: opacity 200ms ease;
        }
        &:nth-child(1){
          top: 39px;
          transform: rotate(25deg);
        }
        &:nth-child(2){
          opacity: 0;
        }
        &:nth-child(3){
          bottom: 40px;
          transform: rotate(-25deg);
        }
      }
    }
  }

}
