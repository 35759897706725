.recruit{

  &__intro{
    .para--lead{
      text-align: center;
      font-weight: 400;
    }
  }


  &__case{
    .case--list{
      max-width: 834px;
      margin: auto;
    }
    .case__heading{
      color: $c_base;
      padding-bottom: 30px;
      @include max-query($break-md){
        display: block;
        text-align: center;
        padding-top: 15px;
      }
      .en{
        color: $c_key1;
        @include f_en;
        font-size: 12px;
        line-height: 1.25;
        display: block;
        margin: 0 0 0 auto;
        padding-left: 1.5em;
        flex-shrink: 0;
        @include max-query($break-lg){
          font-size: 11px;
        }
        @include max-query($break-md){
          padding: 0;
          margin: 1.25em auto 0;
        }
        @include max-query($break-sm){
          font-size: 10px;
          margin: 1em auto 0;
        }
      }
    }
    .case__intro{
      @include min-query($break-md){
        border-bottom: 1px solid $c_gray_pale3;
        padding-bottom: 30px;
      }
      .container--inner{
        @include min-query($break-md){
          display: flex;
          align-items: center;
        }
        .inner--left{
          @include min-query($break-md){
            padding-right: 2em;
            flex: 1;
            p{
              max-width: 425px;
            }
          }
        }
        .inner--right{
          @include max-query($break-md){
            margin: 30px auto;
          }
        }
      }
      .btn--link{
        @include max-query($break-sm){
          width: 100%;
        }
        @include min-query($break-sm){
          min-width: 168px;
        }
      }
    }
  }



}