.img{
  &--fit{
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    object-position: 50% 50%;
    font-family: 'object-fit: cover; object-position: 50% 50%;'; //for_polyfill
    &.fit--top{
      object-position: 50% 0%;
      font-family: 'object-fit: cover; object-position: 50% 0%;'; //for_polyfill
    }
    &.fit--bottom{
      object-position: 50% 100%;
      font-family: 'object-fit: cover; object-position: 50% 100%;'; //for_polyfill
    }
    &.fit--left{
      object-position: 0% 50%;
      font-family: 'object-fit: cover; object-position: 0% 50%;'; //for_polyfill
    }
    &.fit--right{
      object-position: 100% 50%;
      font-family: 'object-fit: cover; object-position: 100% 50%;'; //for_polyfill
    }
  }
  &--contain{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
    font-family: 'object-fit: contain; object-position: 50% 50%;'; //for_polyfill
  }
}
