.fv{

  &--toppage{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
    padding: 50px 220px 50px 140px;
    @include max-query(1280){
      padding: 50px 150px 50px 50px;
    }
    @include max-query($break-lg){
      padding: 100px 100px 0 60px;
      display: flex;
      flex-direction: column;
    }
    @include max-query($break-md){
      padding: 100px 50px 0;
    }
    @include max-query($break-sm){
      padding: 100px 30px 0;
    }
    @include min-query($break-lg){
      min-height: 650px;
    }
    .container--inner{
      width: 100%;
      height: 100%;
      display: flex;
    }
    .inner{
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      width: 100%;
      @include max-query($break-lg){
        flex-direction: column;
      }
    }
    .fv__news{
      display: block;
      color: #fff;
      padding-left: 50px;
      position: relative;
      transition: color 150ms ease;
      &:hover{
        color: $c_key3;
      }
      &:before{
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        background-image: url(../img/icon_info.svg);
        background-size: contain;
        background-position: center center;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -16px;
        @include max-query($break-lg){
          left: 60px;
          top: 35px;
          margin-top: 0;
        }
        @include max-query($break-md){
          left: 50px;
          top: 25px;
        }
        @include max-query($break-sm){
          left: 30px;
          top: 17px;
        }
      }
      @include max-query($break-lg){
        background-color: $c_blur2;
        // backdrop-filter: blur(6px);
        padding: 40px 100px 40px 60+50+px;
        margin: 0 -100px 0 -60px;
      }
      @include max-query($break-md){
        margin: 0 -50px;
        padding: 30px 50px 30px 50+50+px;
      }
      @include max-query($break-sm){
        margin: 0 -30px;
        padding: 20px 30px 20px 30+50+px;
      }
      @include min-query($break-lg){
        position: absolute;
        left: 50px;
        bottom: 25px;
        max-width: calc(100% - 200px);
        display: flex;
        align-items: center;
      }
      .news__date{
        @include f_en;
        line-height: 1;
        @include max-query($break-lg){
          margin-bottom: 0.5em;
        }
        @include min-query($break-lg){
          margin-right: 1em;
        }
      }
    }
    .fv__ph{
      font-size: 0;
      line-height: 1;
      position: relative;
      @include max-query($break-lg){
        width: 100%;
        max-height: 40vh;
        flex: 1;
      }
      @include max-query($break-sm){
        max-height: 30vh;
      }
      @include min-query($break-lg){
        width: 578/1000*100+%;
      }
      &:before{
        content: "";
        display: block;
        padding-top: 403/578*100+%;
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.1);
      }
    }
    .fv__title{
      flex: 1;
      position: relative;
      padding-left: 8%;
      @include max-query(1500){
        padding-left: 6%;
      }
      @include max-query(1280){
        padding-left: 10%;
      }
      @include max-query($break-lg){
        width: 100%;
        flex: none;
        padding: 0 10px;
        padding-left: 0;
      }
      @include max-query($break-md){
        padding-bottom: 10px;
      }
      @include min-query($break-lg){
        margin-right: -578/1000*100+%;
      }
      .main{
        @include f_en_bold;
        font-size: 5vw;
        line-height: 1.2;
        letter-spacing: 0;
        color: #fff;
        @include max-query($break-lg){
          margin-top: -1.85em;
          font-size: 8.8vw;
        }
        @include max-query($break-md){
          font-size: 10.8vw;
        }
      }
      .sub{
        @include f_en_bold;
        font-size: 2.5vw;
        line-height: 1.25;
        letter-spacing: 0;
        color: $c_key1;
        margin: 0.75em 0 0;
        @include max-query($break-lg){
          font-size: 4.15vw;
          margin: 0.5em 0 0;
        }
        @include max-query($break-md){
          font-size: 5.15vw;
        }
      }
      .copy{
        font-size: 1.6vw;
        color: #fff;
        line-height: 2;
        letter-spacing: 0.24em;
        margin: 2em 0 0;
        @include max-query($break-lg){
          font-size: 3.4vw;
          margin: 1.25em 0 0;
        }
        @include max-query($break-md){
          font-size: 4.4vw;
        }
      }
    }
  }


  &--second{
    position: relative;
    z-index: 0;
    height: 480/1366*100+vw;
    @include max-query(1366){
      height: 480px;
    }
    @include max-query($break-md){
      height: auto;
    }

    .fv__bg{
      position: relative;
      z-index: -1;
      &:after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, .2);
      }
      @include max-query($break-md){
        width: 100%;
        &:before{
          content: "";
          display: block;
          padding-top: 245/375*100+%;
        }
      }
      @include min-query($break-md){
        position: absolute;
        top: 0;
        right: 0;
        width: 1069/1366*100+%;
        height: 100%;
        width: calc(1069px + ((var(--vw) - 1366px) * 0.5));
        @include max-query(1258){
          width: calc(var(--vw) - 245px);
        }
        @include max-query($break-lg){
          width: calc(var(--vw) - 177px);
        }
      }
    }

    .fv__title{
      @include max-query($break-md){
        padding: 200px 50px 0;
      }
      @include max-query($break-sm){
        padding: 100px 30px 0;
      }
      @include min-query($break-md){
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;
        padding: 0 50px 85px 60px;
      }
      @include min-query($break-lg){
        padding-left: 140/1366*100+%;
      }
      .sub{
        @include f_en;
        color: $c_key1;
        letter-spacing: 0.6em;
        line-height: 1.25;
        font-size: 1.05vw;
        font-size: 14px;
        margin-bottom: 0.5em;
      }
      .main{
        @include f_en_bold;
        color: #fff;
        letter-spacing: 0;
        line-height: 1.1;
        font-size: 7.5vw;
        font-size: 100px;
        @include max-query($break-lg){
          font-size: 85px;
        }
        @include max-query($break-md){
          font-size: 72px;
          margin-bottom: -0.5em;
          &.line2{
            margin-bottom: -1.55em;
          }
        }
        @include max-query($break-sm){
          // font-size: 60px;
          font-size: 16vw;
        }
      }
      .para--more{
        margin: 25px 0;
        @include margin0;
        .btn--link{
          height: 50px;
        }
      }
    }

    &.narrow{
      height: 430px;
      @include max-query($break-md){
        height: auto;
        padding-top: 75px;
      }
      .fv__bg{
        background-color: $c_blur;
        width: calc(var(--vw) - (1069px + ((var(--vw) - 1366px) * 0.5)));
        height: 100%;
        position: absolute;
        top: 0;
        right: auto;
        left: 0;
        @include max-query(1258){
          width: 245px;
        }
        @include max-query($break-lg){
          width: 177px;
        }
        @include max-query($break-md){
          width: 125px;
        }
        @include max-query($break-sm){
          width: 81px;
        }
      }
      .fv__title{
        padding: 0 160px 60px 100px;
        @include max-query($break-lg){
          padding: 0 100px 40px 60px;
        }
        @include max-query($break-md){
          padding: 50px;
        }
        @include max-query($break-sm){
          padding: 30px;
        }
        .container--view{
          width: 100%;
          margin-bottom: 0;
        }
        // @include min-query($break-lg){
        //   padding-left: 140/1366*100+%;
        // }
        .sub{
          color: #fff;
          margin-bottom: 2em;
          letter-spacing: 0.16em;
          @include max-query($break-md){
            letter-spacing: 0.06em;
            margin-bottom: 1em;
          }
        }
        .main{
          font-size: 70px;
          @include max-query($break-lg){
            font-size: 60px;
          }
          @include max-query($break-md){
            margin: 0;
            font-size: 10.5vw;
          }
        }
        .para--more{
          @include max-query($break-sm){
            .btn--link{
              width: 100%;
              justify-content: flex-start;
              i{
                width: 100%;
              }
            }
          }
        }
      }
      .intro__catch{
        @include max-query($break-md){
          max-width: 450px;
          margin: auto;
        }
        @include max-query($break-sm){
          max-width: 300px;
        }
      }
      .askan-catch{
        @include max-query($break-sm){
          max-width: none;
        }
        .askan-catch-pc{
          @include max-query($break-sm){
            display: none;
          }
        }
        .askan-catch-sp{
          @include min-query($break-sm){
            display: none;
          }
        }
      }
    }

  }


  &--second--nobg{
    padding-top: 170px;
    padding-bottom: 80px;
    @include max-query($break-md){
      padding-top: 155px;
      padding-bottom: 60px;
    }
    @include max-query($break-sm){
      padding-top: 140px;
      padding-bottom: 50px;
    }
    .fv__inner{
      height: 100%;
    }
    .fv__title{
      @include min-query($break-md){
        display: flex;
        align-items: center;
        height: 100%;
      }
      .main{
        @include f_en_bold;
        letter-spacing: 0;
        line-height: 1.1;
        font-size: 70px;
        @include max-query($break-md){
          font-size: 65px;
        }
        @include max-query($break-sm){
          font-size: 60px;
        }
      }
      .sub{
        @include f_en;
        color: $c_key3;
        letter-spacing: 0.16em;
        line-height: 1.25;
        font-size: 16px;
        margin: 0 0 0 auto;
        @include max-query($break-md){
          margin-top: 1em;
        }
      }
    }
  }



  &__intro{
    &--wrap{
      padding-top: 60px;
      padding-bottom: 60px;
      position: relative;
      @include max-query($break-lg){
        padding-top: 50px;
        padding-bottom: 50px;
      }
      @include max-query($break-md){
        padding-top: 40px;
        padding-bottom: 40px;
      }
      @include max-query($break-sm){
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
    .heading--lgmd{
      @include min-query($break-lg){
        font-size: 24px;
      }
    }
  }


}