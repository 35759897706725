.askan{

  &__intro{
    position: relative;
    @include min-query($break-md){
      padding-right: 350px;
    }
    .navi--anchor{
      position: relative;
    }
    .intro__catch{
      position: absolute;
      right: -150/1000*100+%;
      // bottom: 0;
      top: -25%;
      width: 683/1000*100+%;
      max-width: 683px;
      font-size: 0;
      line-height: 1;
      img{
        transform: translateY(-50%);
      }
      @include max-query(1280){
        // right: 0;
      }
      @include max-query($break-lg){
        right: -60px;
        width: 60%;
      }
    }
  }


  &__fig1{
    @include max-query($break-lg){
      max-width: 400px;
      margin: auto;
    }
    @include max-query($break-md){
      padding: 0 5%;
    }
    @include max-query($break-sm){
      padding: 0 13%;
    }
    @include min-query($break-lg){
      max-width: 440px;
      margin: 0 -30px 0 auto;
    }
  }
  &__fig2{
    @include max-query($break-lg){
      max-width: 400px;
      margin: auto;
    }
    @include max-query($break-md){
      padding: 0 5%;
    }
    @include max-query($break-sm){
      padding: 0 13%;
    }
    @include min-query($break-lg){
      max-width: 445px;
      margin: 0 0 0 auto;
    }
  }
  &__fig3{
    @include max-query($break-lg){
      max-width: 400px;
      margin: auto;
    }
    @include max-query($break-md){
      padding: 0 5%;
    }
    @include max-query($break-sm){
      padding: 0 14%;
    }
    @include min-query($break-lg){
      max-width: 445px;
      margin: 0 -50px 0 auto;
    }
  }
  &__fig4{
    @include max-query($break-lg){
      max-width: 400px;
      margin: auto;
    }
    @include max-query($break-md){
      padding: 0 5%;
    }
    @include max-query($break-sm){
      padding: 0 9%;
    }
    @include min-query($break-lg){
      max-width: 480px;
      margin: 0 -50px 0 auto;
    }
  }


}