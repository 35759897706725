.js{

  &--video--pagebg{
    iframe{
      opacity: 0;
      backface-visibility: hidden;
    }
  }


  &--fadeup, &--child--fadeup{
    opacity: 0;
    transform: translateY(20px);
    backface-visibility: hidden;
    @include max-query($break-md){
      transform: translateY(10px);
    }
  }
  &--fadedown, &--child--fadedown{
    opacity: 0;
    transform: translateY(-30px);
    backface-visibility: hidden;
    @include max-query($break-md){
      transform: translateY(-15px);
    }
  }
  &--fadeleft, &--child--fadeleft{
    opacity: 0;
    transform: translateX(-20px);
    backface-visibility: hidden;
    @include max-query($break-md){
      transform: translateX(-10px);
    }
  }


  &--phmask{
    overflow: hidden;
    .mask, .mask__inner{
      overflow: hidden;
      backface-visibility: hidden;
      width: inherit;
      height: inherit;
    }
    .mask{
      transform: translate3d(-100%, 0, 0);
    }
    .mask__inner{
      transform: translate3d(100%, 0, 0);
    }
  }


  &--sticky--opacity-el{
    backface-visibility: hidden;
  }


  &--accordion--continue{
    overflow: hidden;
    height: 160px;
    position: relative;
    backface-visibility: hidden;
    transform: translate3d(0,0,0);
    &.open{
      .js--accordion--btn{
        pointer-events: none;
      }
    }
    &.less_h{
      height: auto;
      .case__footer,.js--accordion--btn{
        display: none;
      }
    }
    .js--accordion--btn, .js--accordion--close{
      @include f_mid;
      color: $c_key2;
      font-size: 16px;
      text-align: center;
      line-height: 1.25;
      letter-spacing: 0.16em;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 0;
      cursor: pointer;
      // transition: color 200ms ease;
      -webkit-tap-highlight-color:rgba(0,0,0,0);
      @include max-query($break-lg){
        font-size: 15px;
      }
      @include max-query($break-md){
        font-size: 14px;
      }
      // @include min-query($break-md){
      //   &:hover{
      //     color: $c_key3;
      //   }
      // }
      &:after{
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        background-image: url(../img/arrow_link-key2.svg);
        transform: rotate(90deg);
        margin-left: 15px;
        border: 1px solid $c_key2;
        border-radius: 50%;
        background-position: center center;
        background-size: 14px;
        background-repeat: no-repeat;
        @include max-query($break-md){
          width: 28px;
          height: 28px;
          background-size: 12px;
        }
        @include max-query($break-sm){
          width: 24px;
          height: 24px;
          background-size: 10px;
          margin-left: 10px;
        }
      }
    }
    .js--accordion--btn{
      background: #fff;
      &:before{
        content: "";
        display: block;
        width: 100%;
        height: 100px;
        background: linear-gradient(0deg, rgba(#fff,1) 25%, rgba(#fff,0) 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    .js--accordion--close{
      bottom: 0;
      right: 0;
      left: auto;
      top: auto;
      // width: auto;
      width: 100%;
      &:after{
        transform: rotate(270deg);
      }
      @include max-query($break-md){
        position: relative;
        bottom: auto;
        right: auto;
        width: 100%;
        margin: 30px auto 0;
      }
    }

    &.accordion--recruit{
      height: 32px;
      @include max-query($break-md){
        height: 28px;
      }
      @include max-query($break-sm){
        height: 24px;
      }
      .js--accordion--btn{
        background: #fff;
      }
      .js--accordion--close{
        width: auto;
      }
    }
  }


  &--fig--flow{
    .flow__line, .flow__item{
      backface-visibility: hidden;
    }
    .flow__line{
      transform-origin: left top;
      transform: scale(0, 1);
      @include max-query($break-md){
        transform: scale(1, 0);
      }
    }
    .flow__item{
      opacity: 0;
      transform: translateY(10px);
      @include max-query($break-md){
        transform: translateY(0);
      }
    }
  }


  &--pulldown{
    margin: 25px auto;
    @include margin0;
    position: relative;
    z-index: 1;
    @include max-query($break-md){
      margin-left: -50px;
      margin-right: -50px;
    }
    @include max-query($break-sm){
      margin-left: -30px;
      margin-right: -30px;
    }
  }
  &--pulldown--btn{
    cursor: pointer;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    background-color: #fff;
    border-bottom: 1px solid #D8D8D8;
    height: 50px;
    @include f_mid;
    color: $c_key1;
    position: relative;
    @include max-query($break-md){
      padding: 0 50px;
    }
    @include max-query($break-sm){
      padding: 0 30px;
    }
    &:after{
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      border-left: 1px solid $c_key2;
      border-bottom: 1px solid $c_key2;
      position: absolute;
      top: 50%;
      right: 50px;
      margin-top: -3px;
      transform: rotate(-45deg);
      @include max-query($break-sm){
        width: 6px;
        height: 6px;
        right: 30px;
      }
    }
    >i{
      height: 100%;
      padding-bottom: 5px;
      display: inline-flex;
      align-items: center;
      position: relative;
      &:after{
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background-color: $c_key1;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    
  }
  &--pulldown--content{
    @include min-query($break-md){
      height: auto !important;
    }
    @include max-query($break-md){
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      height: 0;
      overflow: hidden;
      box-shadow: 0px 3px 6px 0px rgba(#000, 0.15);
      .js--fadeup{
        opacity: 1 !important;
        transform: translateY(0) !important;
      }
      .js--pulldown--inner{
        background-color: #fff;
        @include max-query($break-md){
          padding: 25px 50px;
        }
        @include max-query($break-sm){
          padding: 15px 30px;
        }
      }
    }
  }

  
}