.bg{

  &--page{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    background-image: url(../img/fv_toppage_bg.jpg);
    background-size: cover;
    opacity: 0.5;
    backface-visibility: hidden;
    transform: translate3d(0,0,0);
  }


  &--video{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    // height: var(--vh);
    height: 100vh;
    overflow: hidden;
    opacity: 0.6;
    backface-visibility: hidden;
    transform: translateZ(0);
    &.hidden{
      visibility: hidden;
    }
    iframe, video{
      position: absolute;
      top: 49.95%;
      left: 50%;
      backface-visibility: hidden;
      // width: 100*1920/1080+vh;
      // width: calc(1920 / 1080 * var(--vh));
      width: calc(1920 / 1080 * 100vh);
      min-width: 100%;
      height: 100%;
      min-height: 100*1080/1920+vw;
      transform: translate(-50%, -50%);
      // @media (orientation: portrait){
      //   // width: calc(640 / 1080 * var(--vh));
      //   width: calc(640 / 1080 * 100vh);
      //   min-height: 100*1080/640+vw;
      // }
    }
  }



  &--transparent{
    background-color: transparent !important;
  }
  &--blur{
    backdrop-filter: blur(6px);
    backface-visibility: hidden;
  }
  &--translucent{
    background-color: $c_blur2;
  }

  &--wh{
    background-color: #fff !important;
  }
  &--dark{
    background-color: #000 !important;
  }
  &--blk{
    background-color: $c_blk !important;
  }
  &--gray{
    background-color: $c_gray !important;
  }
  &--gray_pale{
    background-color: $c_gray_pale !important;
  }
  &--gray_pale2{
    background-color: $c_gray_pale2 !important;
  }
  &--gray_pale3{
    background-color: $c_gray_pale3 !important;
  }
  &--key1{
    background-color: $c_key1 !important;
  }
  &--key2{
    background-color: $c_key2 !important;
  }
  &--key2_pale{
    background-color: $c_key2_pale !important;
  }
  &--key3{
    background-color: $c_key3 !important;
  }
}
