.flow{
  display: flex;
  justify-content: space-between;
  z-index: 0;
  position: relative;
  @include max-query($break-md){
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 350px;
    margin-left: 56px;
  }
  @include max-query($break-sm){
    margin-left: 32px;
  }
  &.flow4{
    max-width: 798px;
    margin: auto;
    .flow__line{
      @include min-query($break-md){
        left: 11%;
        width: 78%;
      }
    }
  }
  &.flow3{
    max-width: 592px;
    margin: auto;
    .flow__line{
      @include min-query($break-md){
        left: 15%;
        width: 70%;
      }
    }
  }

  &__line{
    display: block;
    // width: 100%;
    width: 80%;
    height: 1px;
    background-color: $c_key2;
    position: absolute;
    top: 18px;
    left: 10%;
    z-index: -1;
    @include max-query($break-md){
      left: 18px;
      top: 10%;
      width: 1px;
      height: 80%;
    }
  }

  &__item{
    @include f_mid;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.16em;
    text-align: center;
    padding: 75px .5em 0;
    position: relative;
    flex: 1;
    @include max-query($break-lg){
      font-size: 15px;
    }
    @include max-query($break-md){
      font-size: 14px;
      text-align: left;
      padding: 2em 0 2em 70px;
    }
    .num{
      @include f_en_bold;
      line-height: 1;
      letter-spacing: 0;
      text-align: center;
      color: #fff;
      background-color: $c_key1;
      display: block;
      width: 36px;
      height: 36px;
      line-height: 36px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -18px;
      @include max-query($break-md){
        top: 50%;
        left: 0;
        margin-top: -18px;
        margin-left: 0;
      }
    }
  }

}